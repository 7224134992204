import React from 'react';
import { Button } from '@acpaas-ui/react-components';

const Submit = ({
  submit, cancel, addToCart, addToCartAndPay, loading = false, isFree,
}) => {
  const renderSubmit = () => (isFree
    ? <div className="col-md-4 col-sm-12 col-xs-12 u-margin-top-xs">
      <Button type="primary" htmlType="button" onClick={submit} disabled={loading}>
        Inschrijving afrekenen
      </Button>
    </div>
    : <div className="col-md-4 col-sm-12 col-xs-12 u-margin-top-xs">
      <Button type="primary" htmlType="button" onClick={submit} disabled={loading}>
        Inschrijving bevestigen
      </Button>
    </div>
  );

  const renderAddToCart = () => (isFree
    ? <div className="col-md-4 col-sm-12 col-xs-12 u-margin-top-xs">
      <Button type="primary" htmlType="button" onClick={addToCart} disabled={loading}>
          Inschrijving afrekenen
      </Button>
    </div>
    : <>    <div className="col-md-4 col-sm-12 col-xs-12 u-margin-top-xs">
      <Button type="primary" htmlType="button" onClick={addToCart} disabled={loading}>
          Toevoegen aan winkelmand
      </Button>
    </div>
      <div className="col-md-4 col-sm-12 col-xs-12 u-margin-top-xs">
        <Button type="primary" htmlType="button" onClick={addToCartAndPay} disabled={loading}>
          Toevoegen aan winkelmand en betalen
        </Button>
      </div></>);


  const renderCancel = () => (
    <div className="col-md-4 col-sm-12 col-xs-12 u-margin-top-xs">
      <Button id="btn-previous-step" type="primary" htmlType="button" onClick={cancel} negative disabled={loading}>
        Inschrijving annuleren
      </Button>
    </div>
  );


  return (
    <div className="row">
      {addToCart && addToCartAndPay ? renderAddToCart() : renderSubmit()}
      {renderCancel()}
    </div>
  );
};

export default Submit;
