import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ACardSection extends Component {
  renderSection = child => (
    <div>
      <h5>Deelnemer</h5>
      <p className="u-margin-top">Gelieve onderstaande gegevens nog even te controleren alvorens verder te gaan</p>
      <dl className="a-definition-list u-margin-top">
        <dt>A-kaartnummer:</dt>
        <dd>{child.aCardNumber}</dd>
        <dt>Geboortedatum:</dt>
        <dd>{child.birthDate}</dd>
        <dt>Naam:</dt>
        <dd>John Doe</dd>
      </dl>
    </div>
  );

  render() {
    const { child } = this.props;
    return this.renderSection(child);
  }
}

ACardSection.propTypes = {
  child: PropTypes.any,
};

export default ACardSection;
