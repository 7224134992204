import React from 'react';

import TestCondition from '../../Validation/Helpers/Operators';

import './ViewField.scss';


const ParticipantsCondition = () =>
  // const label = 'Bekijk de <a target="_blank" href="https://www.antwerpen.be/info/5e4a997973852727a268d3e3/antwerpse-sportweken-algemene-voorwaarden">inschrijvingsvoorwaarden</a>';
  (
    <div>
      <span>Ja ik ga akkoord met de <a target="_blank" rel="noopener noreferrer" href="https://www.antwerpen.be/info/5e4a997973852727a268d3e3/antwerpse-sportweken-algemene-voorwaarden">inschrijvingsvoorwaarden</a></span>
      {/* <Alert
        type="warning"
        closable={false}>
        <p className="u-padding" dangerouslySetInnerHTML={{ __html: label }}></p>
      </Alert> */}
    </div>
  );
const ViewFieldOutput = ({ data, field, value }) => (
  <div key={field.name} className="u-margin-top">
    <b dangerouslySetInnerHTML={{ __html: field.label || '' }} /><br />
    <span dangerouslySetInnerHTML={{ __html: value }} />
  </div>
);

const checkPrerequisites = (field, value, data) => {
  field.display = true;

  if (!field.prerequisites) return field;

  field.prerequisites.forEach((prerequisite) => {
    switch (prerequisite.type) {
    case 'display':
      if (prerequisite.condition.values) {
        field.display = prerequisite.condition.values.includes(data[prerequisite.condition.field]);
      }
      if (prerequisite.condition.value) {
        field.display = TestCondition(data[prerequisite.condition.field], prerequisite.condition.value, prerequisite.condition.operator);
      }
      break;
      // if (prerequisite.condition.value) {
      //   const { validators } = this.props;
      //   const validator = validators[field.name];

      //   if (validator) {
      //     const match = TestCondition(data[prerequisite.condition.field], prerequisite.condition.value, prerequisite.condition.operator);

      //     if (match && !validator.some(e => e.name === 'isRequired')) {
      //       validator.push({ name: 'isRequired', errorMessage: 'Dit is een verplicht veld' });
      //       this.props.updateValidationField(field, validator);
      //     }

      //     if (!match && validator.some(e => e.name === 'isRequired')) {
      //       const updatedValidators = validator.filter(singleValidator => singleValidator.name !== 'isRequired');
      //       this.props.updateValidationField(field, updatedValidators);
      //     }
      //   }
      // }
      break;
    default:
      return null;
    }
  });

  return field;
};

const ViewField = ({
  field = {},
  data = {},
}) => {
  let value = data[field.name];

  field = checkPrerequisites(field, value, data);

  if (!field.display) return null;

  if (field.type === 'warningCheck' && field.name === 'checkNoParticipantNationalNumber') {
    if (!value) {
      return null;
    }
  }

  if (value === undefined || value === null) {
    return <ViewFieldOutput field={field} data={data} value={value} />;
  }


  if (field.type === 'warningCheck' && !data[field.name]) {
    return null;
  }


  if (field.type === 'checkbox' && field.name === 'participantConditions') {
    return <ParticipantsCondition />;
  }

  if (field.type === 'checkbox') {
    value = value ? 'ja' : 'nee';
  }

  if (field.type === 'select') {
    value = field.options.find(option => option.value === data[field.name]).label;
  }

  return <ViewFieldOutput field={field} data={data} value={value} />;
};


export default ViewField;
