import React from 'react';
import PropTypes from 'prop-types';
import { RadioGroup } from '@acpaas-ui/react-components';

const RadioWrapper = (props) => {
  const onChange = (name, value) => {
    props.onChange(name, value);
  };

  const { field, value } = props;

  if (!field.options) {
    field.options = [];
  }

  return (<RadioGroup
    value={value}
    id={field.id}
    name={`name-${field.name}`}
    className={field.className}
    options={field.options}
    required={field.required}
    label={field.label}
    placeholder={field.placeholder}
    onChange={e => onChange(field.name, e.target.value)}
  />);
};

RadioWrapper.propTypes = {
  field: PropTypes.object,
  onChange: PropTypes.func,
  value: PropTypes.any,
};

RadioWrapper.defaultProps = {
  field: {},
};

export default RadioWrapper;
