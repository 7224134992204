import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Alert } from '@acpaas-ui/react-components';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';

import { ScrollToTop, ScrollTo } from 'components/Layout/ScrollToTop';
import Navigation from '../../Navigation';
import { ConfirmationStep } from '..';
import SingleSection from '../../Sections/SingleSection';
import { Validation } from '../../Validation';

class SingleStep extends Component {
  state = {
    data: {},
    validators: {},
    errors: {},
  };

  componentDidMount() {
    this.setInitialDataModel(this.props.step.sections);
  }

  componentDidUpdate(prevProps) {
    const { index, form, step } = this.props;

    if (!isEqual(prevProps.index, index) && index !== form.steps.length) {
      this.setInitialDataModel(step.sections);
      ScrollToTop();
    }
  }

  setInitialDataModel = (sections) => {
    const { data, child, index } = this.props;
    const stepData = data[`step-${index}`] || {};
    const validators = {};

    sections.forEach((section) => {
      if (section.name === 'participant' && !isEmpty(child)) {
        return;
      }

      section.fields.forEach((field) => {
        const value = stepData[field.name];
        if ((!value || value.length === 0)) {
          stepData[field.name] = field.value;
        }

        validators[field.name] = Validation.createValidators(field);
      });
    });

    this.setState({
      data: stepData,
      validators,
      errors: {},
    });
  };

  onChange = (name, value) => {
    const { errors } = this.state;
    if (!isEmpty(errors)) {
      this.validateField(name, value, this.state.data);
    }

    this.setState(prevState => ({
      data: {
        ...prevState.data,
        [name]: value,
      },
    }));
  };

  onBlur = (name, value) => {
    if (!isEmpty(this.state.errors)) {
      this.validateField(name, value);
    }
  };

  onPreviousStep = () => {
    this.props.onChange(this.state.data);
    this.props.previous();
  };

  onNextStep = () => {
    const errors = this.validate();

    this.setState({ errors });

    if (isEmpty(errors)) {
      this.props.onChange(this.state.data);
      this.props.next();
    } else if (!isEmpty(errors)) {
      // Scroll to first error found.
      const first = Object.getOwnPropertyNames(errors)[0];
      if (document.querySelector(`[name=name-${first}]`)) {
        const el = document.querySelector(`[name=name-${first}]`).closest('.field');
        ScrollTo(el);
      }
    }
  };

  validate = () => {
    const { data, validators } = this.state;
    return Validation.validateFields(data, validators);
  };

  validateField = (name, value) => {
    const { validators } = this.state;
    const errors = Validation.validateField(name, value, validators[name]);

    this.setState(prevState => ({
      errors: {
        ...prevState.errors,
        [name]: errors,
      },
    }));
  };

  updateValidationField = (field, validators) => {
    this.setState(prevState => ({
      validators: {
        ...prevState.validators,
        [field.name]: validators,
      },
    }));
  };

  renderStep = (step) => {
    const { index, child, isWaitingList = false } = this.props;
    const { data, errors, validators } = this.state;
    const sections = step.sections.map((section, sectionIndex) => {
      if (section.fields.length === 0) {
        return null;
      }
      return (<div key={sectionIndex} className="row u-margin-bottom-lg">
        <div className="col-xs">
          <SingleSection
            section={section}
            data={data}
            child={child}
            errors={errors}
            validate={this.validate}
            validators={validators}
            updateValidationField={this.updateValidationField}
            onChange={this.onChange}
            onBlur={this.onBlur}
          />
        </div>
      </div>);
    });

    const showWaitingListText = isWaitingList && step.textFull;

    return (
      <div className="row">
        <div className="col-xs">
          <div className="step">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6">
                {step.notification && <Alert
                  type="info"
                  className="u-margin-top"
                  closable={false}>
                  <span dangerouslySetInnerHTML={{ __html: step.notification }}></span>
                </Alert>
                }
                <div className="content u-margin-top u-margin-bottom" dangerouslySetInnerHTML={{ __html: step.text }}></div>
                {showWaitingListText && <Alert type="danger" className="u-margin-top u-margin-bottom" closable={false}>
                  <span dangerouslySetInnerHTML={{ __html: step.textFull }}></span>
                </Alert>}
                {sections}
                <Navigation index={index} next={this.onNextStep} previous={this.onPreviousStep} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {
      step, index, form, data, submit, cancel, navigate, addToCart, allowAcardCoupons, allowGroupedPurchase, infant
    } = this.props;

    if (index === (form.steps.length - 1)) {
      return <ConfirmationStep
        step={step}
        form={form}
        data={data}
        submit={submit}
        addToCart={addToCart}
        cancel={cancel}
        infant={infant}
        navigate={navigate}
        allowAcardCoupons={allowAcardCoupons}
        allowGroupedPurchase={allowGroupedPurchase}
      />;
    }

    return this.renderStep(step);
  }
}

SingleStep.defaultProps = {
  data: {},
};

SingleStep.propTypes = {
  step: PropTypes.object,
  data: PropTypes.object,
  form: PropTypes.object,
  index: PropTypes.number,
  child: PropTypes.object,
  navigate: PropTypes.func,
  next: PropTypes.func,
  onChange: PropTypes.func,
  previous: PropTypes.func,
  submit: PropTypes.func,
  cancel: PropTypes.func,
};

export default SingleStep;
