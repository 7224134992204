import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getActivity } from 'actions/Activities';
import { Register as StartRegistration } from 'components/Register';
import { SportStadHeader } from 'components/Layout/SportStadHeader';
import MetaTags from 'components/MetaTags';

class Register extends Component {
  componentDidMount() {
    const { activity, match = {} } = this.props;
    if (!activity || activity.code !== match.params.code) {
      return this.props.getActivity(match.params.code);
    }

    return null;
  }

  render() {
    const { activity, config } = this.props;

    return (
      <React.Fragment>
        <MetaTags />
        <SportStadHeader
          title={activity.name}
          backgroundImage={activity.bannerImage}>
        </SportStadHeader>
        <div className="main u-wrapper">
          <div className="u-container u-margin-top-lg u-margin-bottom-lg" role="main">
            <StartRegistration activity={activity} config={config} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.activity.loading,
  error: state.activity.error,
  activity: state.activity.activity,
});

const mapDispatchToProps = dispatch => ({
  getActivity: (code) => {
    dispatch(getActivity(code));
  },
});

Register.propTypes = {
  activity: PropTypes.object,
  getActivity: PropTypes.func,
  loading: PropTypes.bool,
  match: PropTypes.object,
  state: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
