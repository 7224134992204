import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
  DatePicker,
  DayPicker,
  WeekPicker,
  CheckBox,
  Radio,
  Select,
  Street,
  WarningCheck,
  PostalCodePicker,
} from '../Types';
import { Errors } from '../../Errors';
import TestCondition from '../../Validation/Helpers/Operators';

import './SingleField.scss';

const defaultFieldValues = {
  display: true,
};

class SingleField extends Component {
  checkPrerequisites = (field, value, data) => {
    field.prerequisites.forEach((prerequisite) => {
      switch (prerequisite.type) {
        case 'display':
          if (prerequisite.condition.values) {
            field.display = prerequisite.condition.values.includes(data[prerequisite.condition.field]);
          }
          if (prerequisite.condition.value) {
            field.display = TestCondition(data[prerequisite.condition.field], prerequisite.condition.value, prerequisite.condition.operator);
          }
          break;
        case 'empty':
          if (prerequisite.condition.values) {
            if (prerequisite.condition.values.includes(data[prerequisite.condition.field])) {
              if (field.type === 'weekpicker' || field.type === 'daypicker') {
                if (value && value.length > 0) {
                  this.props.onChange(field.name, []);
                }
              }
            }
          }
          break;
        case 'required':
          if (prerequisite.condition.value) {
            const { validators } = this.props;
            const validator = validators[field.name];

            if (validator) {
              const match = TestCondition(data[prerequisite.condition.field], prerequisite.condition.value, prerequisite.condition.operator);

              if (match && !validator.some(e => e.name === 'isRequired')) {
                validator.push({ name: 'isRequired', errorMessage: 'Dit is een verplicht veld' });
                this.props.updateValidationField(field, validator);
              }

              if (!match && validator.some(e => e.name === 'isRequired')) {
                const updatedValidators = validator.filter(singleValidator => singleValidator.name !== 'isRequired');
                this.props.updateValidationField(field, updatedValidators);
              }
            }
          }
          break;
        default:
          return null;
      }
    });
  }

  renderField = (field) => {
    const {
      onChange,
      onBlur,
      validate,
      validators,
      data,
    } = this.props;

    const value = data[field.name];

    field = { ...defaultFieldValues, ...field };

    if (field.prerequisites) {
      this.checkPrerequisites(field, value, data);
    }

    if (!field.display) return null;

    switch (field.type) {
      case 'textfield':
        return <TextField field={field} value={value} data={data} validate={validate} validator={validators[field.name]} onChange={onChange} onBlur={onBlur} />;
      case 'datepicker':
        return <DatePicker field={field} values={value} data={data} validate={validate} onChange={onChange} />;
      case 'daypicker':
        return <DayPicker field={field} values={value} data={data} validate={validate} onChange={onChange} />;
      case 'weekpicker':
        return <WeekPicker field={field} values={value} data={data} validate={validate} onChange={onChange} />;
      case 'checkbox':
        return <CheckBox field={field} value={value} data={data} validate={validate} onChange={onChange} />;
      case 'radio':
        return <Radio field={field} value={value} data={data} validate={validate} onChange={onChange} />;
      case 'select':
        return <Select field={field} value={value} data={data} validate={validate} onChange={onChange} />;
      case 'street':
        return <Street field={field} value={value} data={data} validate={validate} onChange={onChange} />;
      case 'warningCheck':
        return <WarningCheck field={field} value={value} data={data} validate={validate} onChange={onChange} />;
      case 'postalCodePicker':
        return <PostalCodePicker field={field} value={value} data={data} validate={validate} onChange={onChange} onBlur={onBlur} />;
      default:
        return null;
    }
  };

  render() {
    const {
      field = {},
      errors = {},
    } = this.props;

    const renderedField = this.renderField(field);
    if (!renderedField) return null;

    return (
      <div key={field.name} className="field u-margin-top">
        {this.renderField(field)}
        {errors[field.name]
          && Errors.createError(errors[field.name])
        }
      </div>
    );
  }
}

SingleField.defaultProps = {
  validators: {},
};

SingleField.propTypes = {
  data: PropTypes.object,
  validators: PropTypes.object,
  errors: PropTypes.object,
  field: PropTypes.object,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  validate: PropTypes.func,
};

export default SingleField;
