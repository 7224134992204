import {
  FETCH_ACTIVITIES_BEGIN,
  FETCH_ACTIVITIES_SUCCESS,
  FETCH_ACTIVITIES_FAILURE,
  FETCH_ALL_ACTIVITIES_BEGIN,
  FETCH_ALL_ACTIVITIES_SUCCESS,
  FETCH_ALL_ACTIVITIES_FAILURE,
} from 'actions/types';

const initialState = {
  activities: [],
  allActivities: [],
  loading: false,
  error: null,
};

export default function activitiesReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ACTIVITIES_BEGIN:
      return {
        ...state,
        loading: true,
        activities: [],
      };
    case FETCH_ACTIVITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        activities: action.payload.activities,
      };
    case FETCH_ACTIVITIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        activities: [],
      };
    case FETCH_ALL_ACTIVITIES_BEGIN:
      return {
        ...state,
        loading: true,
        allActivities: [],
      };
    case FETCH_ALL_ACTIVITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        allActivities: action.payload.activities,
      };
    case FETCH_ALL_ACTIVITIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        allActivities: [],
      };
    default:
      return state;
  }
}
