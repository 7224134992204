import { startRegistrationForm, fetchRegistrationForm, postRegistrationForm, cancelRegistrationForm } from '../api/RegistrationForm';
import {
  startRegistrationFormBegin,
  startRegistrationFormSuccess,
  startRegistrationFormFailure,
  fetchRegistrationFormBegin,
  fetchRegistrationFormSuccess,
  fetchRegistrationFormFailure,
  submitRegistrationFormBegin,
  submitRegistrationFormSuccess,
  submitRegistrationFormFailure,
  startCancelRegistrationForm,
  cancelRegistrationFormSuccess,
  cancelRegistrationFormFailure,
  CLEAR_REGISTRATION_FORM,
} from './types';

export const beginRegistrationForm = (code, type) => (dispatch) => {
  dispatch(startRegistrationFormBegin());
  return startRegistrationForm(code, type).then(
    data => dispatch(startRegistrationFormSuccess(data)),
    err => dispatch(startRegistrationFormFailure(err)),
  );
};

export const beginCancelRegistrationForm = code => (dispatch) => {
  dispatch(startCancelRegistrationForm());
  return cancelRegistrationForm(code)
    .then(
      data => dispatch(cancelRegistrationFormSuccess(data)),
      err => dispatch(cancelRegistrationFormFailure(err)),
    );
}

export const getRegistrationForm = (code, child) => (dispatch) => {
  dispatch(fetchRegistrationFormBegin());
  return fetchRegistrationForm(code, child).then(
    data => dispatch(fetchRegistrationFormSuccess(data)),
    err => dispatch(fetchRegistrationFormFailure(err)),
  );
};

export const submitRegistrationForm = (code, formData) => (dispatch) => {
  dispatch(submitRegistrationFormBegin());
  return postRegistrationForm(code, formData).then(
    data => dispatch(submitRegistrationFormSuccess(data)),
    err => dispatch(submitRegistrationFormFailure(err)),
  );
};

export const clearRegistrationForm = () => ({
  type: CLEAR_REGISTRATION_FORM,
});
