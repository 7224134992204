import {
  START_REGISTRATION_FORM_BEGIN,
  START_REGISTRATION_FORM_SUCCESS,
  START_REGISTRATION_FORM_FAILURE,
  FETCH_REGISTRATION_FORM_BEGIN,
  FETCH_REGISTRATION_FORM_SUCCESS,
  FETCH_REGISTRATION_FORM_FAILURE,
  SUBMIT_REGISTRATION_FORM_BEGIN,
  SUBMIT_REGISTRATION_FORM_SUCCESS,
  SUBMIT_REGISTRATION_FORM_FAILURE,
  CLEAR_REGISTRATION_FORM,
} from 'actions/types';

import { Extender } from './Extender';

const initialState = {
  form: null,
  loading: false,
  error: null,
};

export default function registrationFormReducer(state = initialState, action) {
  switch (action.type) {
  case START_REGISTRATION_FORM_BEGIN:
  case FETCH_REGISTRATION_FORM_BEGIN:
  case SUBMIT_REGISTRATION_FORM_BEGIN:
    return {
      ...state,
      loading: true,
      error: null,
      type: Extender.createType(action.type),
      result: Extender.createResult(action.type),
    };
  case START_REGISTRATION_FORM_SUCCESS:
  case FETCH_REGISTRATION_FORM_SUCCESS:
    return {
      ...state,
      loading: false,
      form: action.payload.form || state.form,
      error: null,
      type: Extender.createType(action.type),
      result: Extender.createResult(action.type),
    };
  case SUBMIT_REGISTRATION_FORM_SUCCESS:
    return {
      ...state,
      loading: false,
      form: state.form,
      registration: action.payload.registration,
      error: null,
      type: Extender.createType(action.type),
      result: Extender.createResult(action.type),
    };
  case START_REGISTRATION_FORM_FAILURE:
  case FETCH_REGISTRATION_FORM_FAILURE:
  case SUBMIT_REGISTRATION_FORM_FAILURE:
    console.log('[ERROR] - RegistrationForm.js - Failure submitting registrationform - action: ', action);
    return {
      ...state,
      loading: false,
      error: action.payload.error,
      type: Extender.createType(action.type),
      result: Extender.createResult(action.type),
    };

  case CLEAR_REGISTRATION_FORM:
    return Object.assign({}, initialState);
  default:
    return state;
  }
}
