import { getToken } from 'util/localStorage';

export default function headers(skipToken = false) {
  const defaultHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  const token = getToken();
  const authorizationHeader = token ? { Authorization: `Bearer ${token}` } : {};
  const authorization = skipToken
    ? {}
    : authorizationHeader;
  return { ...defaultHeaders, ...authorization };
}
