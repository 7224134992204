import { combineReducers } from 'redux';
import pageReducer from 'reducers/Page';
import activitiesReducer from 'reducers/Activities';
import activityReducer from 'reducers/Activity';
import registrationFormReducer from 'reducers/RegistrationForm';
import registrationsReducer from 'reducers/RegistrationsReducer';
import authentication from 'reducers/Authentication.reducer';
import registrationsHistoryReducer from 'reducers/RegistrationsHistory';
import vouchersReducer from 'reducers/Vouchers';

const rootReducer = combineReducers({
  pageReducer,
  auth: authentication,
  activitiesReducer,
  activity: activityReducer,
  registrationForm: registrationFormReducer,
  registrations: registrationsReducer,
  registrationsHistory: registrationsHistoryReducer,
  vouchers: vouchersReducer,
});

export default rootReducer;
