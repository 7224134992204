import { fetchPage } from '../api/Pages';
import {
  fetchPageBegin,
  fetchPageSuccess,
  fetchPageFailure,
} from './types';

const getPage = slug => (dispatch) => {
  dispatch(fetchPageBegin());
  return fetchPage(slug).then(
    data => dispatch(fetchPageSuccess(data)),
    err => dispatch(fetchPageFailure(err)),
  );
};

export default getPage;
