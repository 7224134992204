import React, { useState } from 'react';

import { Switch } from '@acpaas-ui/react-components';
import ViewStep from '../ViewStep';
import { Submit } from '../../Navigation';
import './ConfirmationStep.scss';
import { euroFormat } from 'util/currency';

function getTotalPrice(appliedCoupon, infant, form) {
  const hasFosterCare = !!infant.hasFosterCare;
  const registrationPrice = hasFosterCare ? form.fosterCarePrice : form?.price;
  return appliedCoupon ? Math.max(0, registrationPrice - 2) : registrationPrice;
}

const ConfirmationStep = ({
  data,
  form,
  step,
  infant,
  submit,
  addToCart,
  cancel,
  navigate,
  allowAcardCoupons,
  allowGroupedPurchase,
}) => {
  const [loading, setLoading] = useState(false);
  const [appliedCoupon, setAppliedCoupon] = useState(false);
  const hasCoupons = !!form?.acard?.coupons?.length;
  const totalPrice = getTotalPrice(appliedCoupon, infant, form);

  const submitForm = () => {
    setLoading(true);
    return submit(appliedCoupon)
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  const addRegistrationToCart = () => {
    setLoading(true);
    return submit(appliedCoupon)
      .then((resp) => {
        addToCart(resp?.payload?.registration?.id, false, !totalPrice);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const addRegistrationToCartAndPay = () => {
    setLoading(true);
    return submit(appliedCoupon)
      .then((resp) => {
        addToCart(resp?.payload?.registration?.id, true, !totalPrice);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onApplyCoupon = (e) => {
    if (form?.acard?.coupons?.length) {
      const coupon = form?.acard?.coupons[0];
      return e.target.checked && !!coupon ? setAppliedCoupon(coupon?.chequeId) : setAppliedCoupon(null);
    }
  };

  const renderStep = () => {
    const steps = form.steps.map((singleStep, index) => {
      if (index < (form.steps.length - 1)) {
        return (<ViewStep key={index} stepIndex={index} step={singleStep} title={singleStep.name} data={data[`step-${index}`]} navigate={navigate} />);
      }

      return null;
    });

    return (
      <>
        {step.text
          && <div className="row u-margin-top">
            <div className="col-md-12">
              <p className="u-margin-top" dangerouslySetInnerHTML={{ __html: step.text }}></p>
            </div>
          </div>
        }
        <div className="row u-margin-top ">
          <div className="col-md-8">
            {steps}
            {
              <div className='view-step p-confirmation-row u-margin-top-lg'>
                <h5 className='u-margin-right'>Kostprijs</h5>
                <h5>{euroFormat(totalPrice)}</h5>
              </div>
            }
            <div className='u-margin-top'>
              <Submit
                submit={submitForm}
                cancel={cancel}
                addToCart={addToCart ? addRegistrationToCart : null}
                addToCartAndPay={addToCart ? addRegistrationToCartAndPay : null}
                loading={loading}
                isFree={totalPrice === 0}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  return renderStep(step);
};

export default ConfirmationStep;
