import React from 'react';

function createError(error) {
  if (error) {
    return (<span className="u-text-danger">{error}</span>);
  }
  return;
}

export const Errors = {
  createError,
};
