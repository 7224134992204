import React, { useEffect, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import scrollToElement from 'scroll-to-element';

function ScrollTo(element) {
  if (element) {
    scrollToElement(element, {
      offset: 0,
      duration: 500,
    });
  }
}

// eslint-disable-next-line react/prop-types
function ScrollToTopFunction({ history, children }) {
  useEffect(() => {
    const unlisten = history.listen(() => {
      const element = document.querySelector('#root');
      if (element) {
        ScrollTo(element);
      }
    });
    return () => {
      unlisten();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <Fragment>{children}</Fragment>;
}

// eslint-disable-next-line react/prop-types
function ScrollToTop() {
  const element = document.querySelector('#root');
  if (element) {
    ScrollTo(element);
  }
}

const ScrollToTopRouter = withRouter(ScrollToTopFunction);

export {
  ScrollToTopRouter,
  ScrollToTop,
  ScrollTo,
};
