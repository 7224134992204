/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { cloneElement } from 'react';
import ReactTooltip from 'react-tooltip';

import './Tooltip.scss';

export function Tooltip({
  anchor, anchorId, location = 'top', text,
}) {
  return <>
  <a data-tip data-for={`${anchorId}-tooltip`}>
    {cloneElement(anchor, { id: anchorId, 'aria-describedby': `${anchorId}-tooltip` })}
  </a>
  <ReactTooltip
    id={`${anchorId}-tooltip`}
    place={location}
    aria-haspopup={true}
    effect="solid">
    <div className="u-margin-xxs" >
      <p>{text}</p>
    </div>
  </ReactTooltip>
</>;
}

export default Tooltip;
