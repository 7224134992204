import React from 'react';
import PropTypes from 'prop-types';
import { Datepicker } from '@acpaas-ui/react-components';

const defaultDatePicker = {
  display: true,
  view: 'dayPicker',
};

const DatePickerWrapper = (props) => {
  const onChange = (field, date) => {
    props.onChange(field.name, date);
  };

  const { values = [] } = props;
  let { field } = props;
  field = { ...defaultDatePicker, ...field };

  return (<Datepicker
    open={values.length > 0}
    {...(field.multipleDates ? { selectedDates: values } : { activeDate: values })}
    id={field.id}
    name={`name-${field.name}`}
    label={field.label}
    required={field.required}
    readOnly={field.readOnly}
    autoClose={field.autoClose}
    noWeekends={field.noWeekends}
    mask={'99/99/9999'}
    format={'DD/MM/YYYY'}
    onChange={date => onChange(field, date)}
  />);
};

DatePickerWrapper.propTypes = {
  field: PropTypes.any,
  onChange: PropTypes.any,
  value: PropTypes.any,
};

export default DatePickerWrapper;
