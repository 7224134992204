import { fetchActivities, fetchAllActivities, fetchActivity } from '../api/Activities.js';
import {
  fetchActivitiesBegin,
  fetchActivitiesSuccess,
  fetchActivitiesFailure,
  fetchAllActivitiesBegin,
  fetchAllActivitiesSuccess,
  fetchAllActivitiesFailure,
  fetchActivityBegin,
  fetchActivitySuccess,
  fetchActivityFailure,
} from './types';

export const getActivities = (slug, params) => (dispatch) => {
  dispatch(fetchActivitiesBegin());
  return fetchActivities(slug, params).then(
    data => dispatch(fetchActivitiesSuccess(data)),
    err => dispatch(fetchActivitiesFailure(err)),
  );
};

export const getAllActivities = (slug, params) => (dispatch) => {
  dispatch(fetchAllActivitiesBegin());
  return fetchAllActivities(slug, params).then(
    data => dispatch(fetchAllActivitiesSuccess(data)),
    err => dispatch(fetchAllActivitiesFailure(err)),
  );
};

export const getActivity = code => (dispatch) => {
  dispatch(fetchActivityBegin());
  return fetchActivity(code).then(
    data => dispatch(fetchActivitySuccess(data)),
    err => dispatch(fetchActivityFailure(err)),
  );
};
