import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import SingleField from '../../Fields/SingleField';
import ViewField from '../../Fields/ViewField';
import ACardSection from '../ACardSection';

class SingleSection extends Component {
  renderFields = (fields) => {
    const {
      onChange,
      onBlur,
      validate,
      validators,
      updateValidationField,
      data,
      errors,
    } = this.props;

    return fields.map((field) => {
      if (field.readOnly) {
        return (
          <ViewField
            key={field.name}
            field={field}
            data={data}
          />
        );
      }

      return (<SingleField
        key={field.name}
        field={field}
        data={data}
        errors={errors}
        validators={validators}
        validate={validate}
        updateValidationField={updateValidationField}
        onChange={onChange}
        onBlur={onBlur}
      />);
    });
  };

  renderSection = (section) => {
    const { child } = this.props;

    if (section.name === 'participant' && !isEmpty(child)) {
      return <ACardSection child={child} />;
    }

    return (
      <div>
        {section.title
          && <h5>{section.title}</h5>
        }
        {this.renderFields(section.fields)}
      </div>
    );
  };

  render() {
    const { section } = this.props;
    return this.renderSection(section);
  }
}

SingleSection.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  child: PropTypes.object,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  section: PropTypes.object,
  validate: PropTypes.func,
};

export default SingleSection;
