/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@acpaas-ui/react-components';

import DatesFlyoutButton from 'components/DatesFlyoutButton';
import { navigateToLatestPage } from 'util/links';
import RegisterButton from '../RegisterButton';
import Counter from '../Counter';

import './ActivityDetail.scss';
import { withRouter } from 'react-router-dom';

class ActivityDetail extends Component {
  renderOffersButton = () => {
    const goToOverview = (e) => {
      e.preventDefault();
      navigateToLatestPage(this.props.history, true);
    };

    return (
      <a href='#' className="back-to-activities-button" onClick={goToOverview}>
        <span className="fa fa-chevron-left" />
        <span className="back-link"><b>Terug naar het aanbod</b></span>
      </a>
    );
  }

  renderRegistrationButton = () => (
    <a
      href="/mijn-inschrijvingen"
      className="back-to-activities-button"
      onClick={() => this.props.history.push('/mijn-inschrijvingen')}>
      <span className="fa fa-chevron-left" />
      <span className="back-link"><b>Terug naar mijn inschrijvingen</b></span>
    </a>
  )

  renderBackButton = (fromRegistration = false) => (
    <div className="row u-margin-bottom">
      <div className="col-xs">
        {!fromRegistration && this.renderOffersButton()}
        {fromRegistration && this.renderRegistrationButton()}
      </div>
    </div>
  )

  render() {
    const { activity, history } = this.props;
    const {
      detailFields = [],
      counter = {},
      contactPerson = '',
      allDates = [],
      type,
    } = activity;

    const contactData = contactPerson.replace(/\n/g, '<br />');

    const query = new URLSearchParams(this.props.location.search);
    const fromRegistration = query.get('fromRegistration');

    return (
      <React.Fragment>
        {this.renderBackButton(fromRegistration)}
        <div id="activity-detail" className="u-margin-top">
          <section id="title-section" className="row">
            <div className="col-xs-12">
              {!!counter
                && <Counter {...activity} className="u-margin-top" />
              }
              {activity.wheelchairAccessible && <Icon name={'wheelchair fa-fw'} />}
            </div>
          </section>

          <section id="description-section" className="row u-margin-top">
            <div className="col-xs-12">
              <h3>Omschrijving</h3>
              <div className="u-margin-top" dangerouslySetInnerHTML={{ __html: activity.description }} />
            </div>
          </section>

          <section id="details-section" className="row u-margin-top">
            <div className="col-xs-12">
              <h3>Details</h3>
              <div className="row">
                <div className="col-xs-12 col-md-6">
                  <ul className="a-list has-icon-left">
                    {detailFields.map((field, index) => (
                      <li key={index}>
                        <Icon name={`${field.icon} fa-fw`} />
                        <div
                          className="inline-block"
                          dangerouslySetInnerHTML={{ __html: field.text }}
                        />
                        {(type === 'periodieke activiteit' && index === 0) && <DatesFlyoutButton dates={allDates} />}
                      </li>
                    ))}
                  </ul>
                  {contactPerson
                    && <React.Fragment>
                      <h5>Contactgegevens</h5>
                      <p dangerouslySetInnerHTML={{ __html: contactData }} />
                    </React.Fragment>
                  }
                  <RegisterButton {...activity} history={history} />
                </div>
                <div className="col-xs-12 col-md-6">
                  <img src={activity.imageUrl} style={{ maxWidth: '100%' }} alt="locatie" />
                </div>
              </div>
            </div>
          </section>
        </div>
      </React.Fragment>
    );
  }
}

ActivityDetail.propTypes = {
  activity: PropTypes.object,
  history: PropTypes.object,
};

export default withRouter(ActivityDetail);
