import React from 'react';
import { Button } from '@acpaas-ui/react-components';
import './RegisterButton.css';

const ButtonLink = ({
  history, text, disabled, link,
}) => (
  <Button
    className="u-margin-top u-margin-bottom"
    disabled={disabled}
    onClick={() => history.push(link)}>
    {text}
  </Button>
);

const RegisterButton = ({
  status,
  shouldRegister = false,
  registrationStart,
  hasWaitingList = false,
  registrationEnd,
  registrationEnabled = false,
  pageSlug,
  code,
  history,
  textOverwrite = false,
  counter = {},
}) => {
  if (!shouldRegister) {
    return null;
  }


  const today = Date.now();
  const startDate = new Date(registrationStart);
  const endDate = new Date(registrationEnd);
  const canRegisterAccordingToDate = today >= startDate.valueOf() && today <= endDate.valueOf();

  if (!registrationEnabled || !canRegisterAccordingToDate) {
    return null;
  }

  const text = textOverwrite
    ? 'Andere kinderen inschrijven voor deze activiteit'
    : 'Inschrijven';
  const waitingListText = textOverwrite
    ? 'Andere kinderen inschrijven op de wachtlijst'
    : 'Inschrijven op de wachtlijst';

  const link = `/${pageSlug}/activiteit/${code}/inschrijven`;
  if (status === 'full') {
    if (hasWaitingList) {
      return <ButtonLink
        history={history}
        link={`${link}?type=wachtlijst`}
        text={waitingListText}
      />;
    }

    return <ButtonLink
      history={history}
      disabled={true}
      text={text}
    />;
  }

  if (status === 'registrationsDisabled') {
    return <ButtonLink
    history={history}
    disabled={true}
    text={text}
    />;
  }



  const { free = 0 } = counter;

  if (free > 0) {
    return <ButtonLink
      history={history}
      link={link}
    text={text}
    />;
  }

  if (status === 'registrationsEnabled' || hasWaitingList) {
    return <ButtonLink
    history={history}
    link={`${link}?type=wachtlijst`}
    text={waitingListText}
    />;
  }

  return <div className="register-full-no-button">Volzet</div>;
};

export default RegisterButton;
