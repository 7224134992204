import moment from 'moment';

function calculateAgeOnTime(startDate, endDate, birthDate) {
  const start = moment(startDate);

  const end = moment(endDate);
  const birth = moment(birthDate);
  return {
    ageStart: moment.duration(start.diff(birth)),
    ageEnd: moment.duration(end.diff(birth)),
  };
}

function recalculateMinAge(minYears, minMonths) {
  if (minMonths === 0) {
    return moment.duration({
      years: minYears - 1,
      months: 11,
      days: 23,
    });
  }

  if (minMonths === 11) {
    return moment.duration({
      years: minYears,
      months: 10,
      days: 25,
    });
  }

  return moment.duration({
    years: minYears,
    months: minMonths - 1,
    days: 24,
  });
}

function recalculateMaxAge(maxYears, maxMonths) {

  if (maxMonths === 11) {
    return moment.duration({
      years: maxYears + 1,
      months: 0,
      days: 6,
    });
  }

  return moment.duration({
    years: maxYears,
    months: maxMonths,
    days: 7,
    hours: 1,
  });
}

function formatDate(date) {
  const [day, month, year] = date.split('/');
  return `${year}-${month}-${day}`;
}

export function isCorrectAge(value, {
  minYears = Number.NEGATIVE_INFINITY,
  maxYears = Number.POSITIVE_INFINITY,
  minMonths = 0,
  maxMonths = 0,
  start,
  end,
}) {
  if (!value) {
    return true;
  }

  const minAge = recalculateMinAge(minYears, minMonths);
  const maxAge = recalculateMaxAge(maxYears, maxMonths);
  const birthdate = formatDate(value);
  const { ageStart, ageEnd } = calculateAgeOnTime(start, end, birthdate);
  const minDiffStart = ageStart.asMilliseconds() - minAge.asMilliseconds();
  // console.log(minDiffStart);
  const minDiffEnd = ageEnd.asMilliseconds() - minAge.asMilliseconds();
  // console.log(minDiffEnd);
  const maxDiffEnd = maxAge.asMilliseconds() - ageEnd.asMilliseconds();
  // console.log(maxDiffEnd);
  const maxDiffStart = maxAge.asMilliseconds() - ageStart.asMilliseconds();
  // console.log(maxDiffStart);
  return minDiffStart >= 0 && minDiffEnd >= 0 && maxDiffEnd >= 0 && maxDiffStart >= 0;
}


export function buildErrorMessageAge({
  minYears = Number.NEGATIVE_INFINITY,
  maxYears = Number.POSITIVE_INFINITY,
  minMonths = 0,
  maxMonths = 0,
}) {
  let message = 'De toegelaten leeftijd voor deze activiteit is';
  if (minYears != null) { // want kan ook 0 zijn
    message += ` ouder dan ${minYears}`;
  }

  if (minMonths) {
    message += ` ${minMonths} maanden`;
  }

  if (minYears != null && maxYears != null) {
    message += ' en';
  }

  if (maxYears !== false) {
    message += ` jonger dan ${maxYears}`;
  }

  if (minMonths) {
    message += ` ${maxMonths} maanden`;
  }

  return `${message}.`;
}
