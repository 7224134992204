import { Badge, Icon } from '@acpaas-ui/react-components';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import './ShoppingCartButton.scss';

const ShoppingCartButton = ({ amount = 0 }) => {
  const history = useHistory();
  const { slug } = useParams();

  return <div className='m-cart-button'>
    <button className="a-button has-icon-right" onClick={() => history.push(`${slug}/winkelmand`)} >
      <Icon name="shopping-cart" />
            Winkelmand
      {amount ? <Badge className="m-cart-button__badge" type="warning">{amount}</Badge> : null}
    </button>
  </div>;
};

export default ShoppingCartButton;
