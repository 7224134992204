import {
  authActionSucces, fetchUserBegin, fetchUserFailure, fetchUserSuccess,
} from 'actions/types';
import { getMe } from 'api/Auth';

export function fetchUser(onSuccess) {
  return (dispatch) => {
    dispatch(fetchUserBegin());
    return getMe().then((resp) => {
      dispatch(authActionSucces(resp));
      dispatch(fetchUserSuccess());
      if (onSuccess) {
        onSuccess(resp);
      }
    }).catch((err) => {
      dispatch(fetchUserFailure(err));
    });
  };
}


export default { fetchUser };
