import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@acpaas-ui/react-components';

const DISABLED_AUTOCOMPLETE_FIELDS = ['participantPostBoxNumber', 'parentFirstname', 'parentLastname', 'participantNumber', 'participantNationalRegisterNumber'];

const TextFieldWrapper = (props) => {
  const onChange = (name, value) => {
    props.onChange(
      name,
      value,
    );
  };

  const onBlur = (name, value) => {
    props.onBlur(name, value);
  };

  const { field, value, validator = {} } = props;

  return (<TextField
    value={value}
    id={field.id}
    name={`name-${field.name}`}
    className={field.className}
    required={Object.keys(validator).length !== 0 && validator.some(e => e.name === 'isRequired')}
    label={field.label}
    placeholder={field.placeholder}
    mask={field.mask}
    autoComplete={DISABLED_AUTOCOMPLETE_FIELDS.includes(field.name) && 'new-password'}
    onChange={e => onChange(field.name, e.target.value)}
    onBlur={e => onBlur(field.name, e.target.value)}
  />);
};

TextFieldWrapper.propTypes = {
  field: PropTypes.object,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.any,
};

TextFieldWrapper.defaultProps = {
  field: {},
  onBlur: () => { },
};

export default TextFieldWrapper;
