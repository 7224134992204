import React, { useEffect, useState } from 'react';
import {
  Route, Switch, withRouter, Redirect, useHistory, useLocation,
} from 'react-router-dom';
import 'core-js/stable';
import 'moment/locale/nl';
import { fetchConfig } from 'api/Config';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser } from 'actions/Auth';

import { authenticatedSelector, currentUserSelector } from 'selectors/auth.selectors';
import Moment from 'moment';
import Favicon from 'react-favicon';
import {
  Footer,
  Environment,
  SubFooter,
} from '@acpaas-ui/react-components';

import Header from 'components/Layout/Header';
import { default as momentTz } from 'moment-timezone/builds/moment-timezone.min';
import { clearToken } from 'util/localStorage';
import { routes } from './routes';
import NotificationsWrapper from './components/Layout/Notifications/NotificationsWrapper';
import { ScrollToTopRouter } from './components';

/**
 * More ACPaaS UI documentation can be found here:
 * https://digipolisantwerp.github.io/acpaas-ui_react/
 */
import './App.scss';
import { TIMEZONE } from 'constants/settings';


Moment.locale('nl');
momentTz.tz.add({ timezone: TIMEZONE });
momentTz.tz.setDefault('Europe/Brussels');

const App = () => {
  const env = Environment.getEnvironment();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [config, setConfig] = useState();


  const user = useSelector(currentUserSelector);
  const authenticated = useSelector(state => authenticatedSelector(state));

  const redirect = 'sportweken';

  useEffect(() => {
    const fetchConfigFile = async () => {
      const configFile = await fetchConfig();
      setConfig(configFile);
    };

    const getUser = async () => {
      dispatch(fetchUser());
    };
    const queryParams = new URLSearchParams(location.search);

    if (queryParams.has('fbclid')) {
      queryParams.delete('fbclid');
      history.replace({
        search: queryParams.toString(),
      });
    }

    if (authenticated && !user.userName) {
      getUser();
    }

    if (!authenticated) {
      clearToken();
    }

    fetchConfigFile();
  }, [authenticated, dispatch, history, location.search, user]);


  return (
    <div className="App">
      <Header user={user} authenticated={authenticated} slug={location?.pathname.split('/')[1]} config={config}/>
      <Favicon url={`${process.env.PUBLIC_URL}/images/favicon-${env}.png`} />
      <NotificationsWrapper />
      <ScrollToTopRouter>
        <Switch>
          {routes.map((route, i) => (
            <Route
              key={i} {...route}
              render={props => <route.componentName {...props} {...route} config={config} />}
            />
          ))}
          <Redirect from='/' to={redirect} />
        </Switch>
      </ScrollToTopRouter>
      <div>
        <SubFooter type="antwerpen" />
        <Footer />
      </div>
    </div>
  );
};

export default withRouter(App);
