function testCondition(fieldValue, value, operator) {
  switch (operator) {
    case '==':
      return String(value) === String(fieldValue);
    case '!=':
      return String(value) !== String(fieldValue);
    case '<':
      return value < fieldValue;
    case '>':
      return value > fieldValue;
    case '<=':
      return value <= fieldValue;
    case '>=':
      return value >= fieldValue;
    default:
      return String(value) === String(fieldValue);
  }
}

export default testCondition;
