import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withSizes from 'react-sizes';
import { compose } from 'redux';
import DefaultHeader from 'images/default-header.jpg';


import './SportStadHeader.scss';

class SportStadHeader extends Component {
  render() {
    const {
      title,
      subtitle,
      backgroundImage,
      isMobile,
    } = this.props;

    return (
      <div className="sportstad-header-container">
        <div className="sportstad-header-blurred-background" style={{
          backgroundImage: backgroundImage ? `url('${backgroundImage}')` : `url(${DefaultHeader})`,
        }}></div>
        <div
          className="sportstad-header"
          style={{
            backgroundImage: backgroundImage ? `url('${backgroundImage}')` : `url(${DefaultHeader})`,
          }}>
          <div className="u-wrapper sportstad-header-wrapper">
            <div className="u-container">
              <h1 className={isMobile ? 'h5' : ''}>{title}</h1>
              {subtitle && <h2 className="u-margin-top">{subtitle}</h2>}
            </div>
          </div>
        </div>
        <div className="u-wrapper">
          <div className="u-container" style={{ position: 'relative' }}>
          </div>
        </div>
      </div>
    );
  }
}

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 480,
});

SportStadHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  backgroundImage: PropTypes.string,
  menuItems: PropTypes.array,
};

SportStadHeader.defaultProps = {
  title: '',
  subtitle: '',
  backgroundImage: 'https://www.sportstad.be/site2/img/a-stad_kanaalfoto.jpg',
  menuItems: [],
  children: null,
};

export default compose(
  withSizes(mapSizesToProps),
)(SportStadHeader);
