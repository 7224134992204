import React from 'react';
import { Helmet } from 'react-helmet';

const MetaTags = () => (
  <Helmet>
    <title>Sporting A - Schrijf je hier in op onze sportactiviteiten</title>
    <meta name="description" content={'Op zoek naar een leuke activiteit? Dan ben je aan het juiste adres! Hier kan je je inschrijven voor de sportactiviteiten van Sporting A.'} />
  </Helmet>
);

export default MetaTags;
