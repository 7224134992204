/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { navigateToLatestPage } from 'util/links';
import {
  Button,
} from '@acpaas-ui/react-components';

import { LOGIN_URL, REGISTER_URL } from 'constants/settings';
import './LoginPopUp.scss';


const LoginPopUp = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const { allowAnonymous } = props;

  const closePopUp = () => {
    setIsOpen(true);
  };

  const goToLogin = () => {
    window.location.href = `${LOGIN_URL}?fromUrl=${window.location.pathname}`;
  };

  const goToRegister = () => {
    window.location.href = `${REGISTER_URL}?fromurl=${window.location.pathname}`;
  };

  const goToOverview = (e) => {
    e.preventDefault();
    navigateToLatestPage(history, true);
  };


  if (isOpen) {
    return (null);
  }

  return (
    <div className="astad-login-popup">
      <div className="m-overlay is-active">
        <div className={`m-overlay__inner ${allowAnonymous ? 'allow-anonymous' : 'required-login'}`}>
          <div className="m-modal__header">
            <span><b>Aanmelden</b></span>
          </div>
          {allowAnonymous
            && <div className="m-modal__content m-modal-anonymous u-margin-bottom">
              <Button id="btn-no-login" type="primary" icon="arrow-right" htmlType="button" onClick={() => { closePopUp(); }} outline block>
                Verdergaan zonder A-profiel
              </Button>
            </div>
          }
          <div className="m-modal__content m-modal-aprofile u-margin-top">
            <p>Door aan te melden met een A-profiel:</p>
            <ul>
              <li>hoeft u niet telkens dezelfde gegevens in te vullen</li>
              <li>kan u makkelijk de status van een aanvraag of melding opvolgen</li>
              <li>kunnen we u gepersonaliseerde inhoud aanbieden</li>
              <li>kunnen we u vlot om meer informatie vragen mocht dit nodig zijn</li>
            </ul>
            <p>Nog geen A-profiel? <a href='#' onClick={() => goToRegister()}>Registreer uw A-profiel</a></p>

            <Button type="primary" icon="arrow-right" htmlType="button" onClick={goToOverview} outline block>
              Terug naar aanbod
            </Button>

            <Button icon="user" id="button-login" onClick={() => goToLogin()}>Aanmelden</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

LoginPopUp.propTypes = {
  allowAnonymous: PropTypes.bool,
};

LoginPopUp.defaultProps = {
  allowAnonymous: false,
};

export default LoginPopUp;
