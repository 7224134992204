import React from 'react';
import ReactDOM from 'react-dom';

import { notificationSettings } from './settings';
import Notification from './Notification';

function renderNotification(type, message) {
  const target = document.getElementById(notificationSettings.wrapperId);
  ReactDOM.render(<Notification
    type={type}
    message={message}
  />, target);
}

function create(type, message) {
  renderNotification(type, message);
}

function notification(message) {
  renderNotification('notification', message);
}

function info(message) {
  renderNotification('info', message);
}

function warning(message) {
  renderNotification('warning', message);
}

function error(message) {

  renderNotification('error', message || 'Er ging iets mis, probeer het opnieuw');
}

function success(message) {
  renderNotification('success', message);
}


// eslint-disable-next-line import/prefer-default-export
export const Notifications = {
  create,
  info,
  notification,
  warning,
  error,
  success,
};
