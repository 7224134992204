import { fetchVouchersOverviewBegin, fetchVouchersOverviewFailure, fetchVouchersOverviewSuccess } from 'actions/types';
import { fetchVouchersHistory } from 'api/Voucher';

export function fetchVouchersOverview(pagination) {
  return (dispatch) => {
    dispatch(fetchVouchersOverviewBegin());
    return fetchVouchersHistory(pagination).then((resp) => {
      dispatch(fetchVouchersOverviewSuccess(resp));
    }).catch((err) => {
      dispatch(fetchVouchersOverviewFailure(err));
    });
  };
}


export default { fetchVouchersOverview };
