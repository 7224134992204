import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, TextField } from '@acpaas-ui/react-components';
import { Notifications } from 'components/Layout/Notifications';
import luhn from 'fast-luhn';

const WithAcardSection = (props) => {
  const [acardError, setACardError] = useState(null);
  const {
    updateInfant, form, infant, startFormRegistration,
  } = props;

  let isButtonDisabled = form.loading;

  const validateAcardInfo = () => {
    if (!infant.aCardNumber || !infant.birthDate) {
      Notifications.error('Gelieve een a-kaartnummer en geboortedatum in te vullen.');
      return;
    }

    const toCheck = infant.aCardNumber.replace(/\./gi, '');
    if (!luhn(toCheck) || toCheck.indexOf('9000') !== 0) {
      setACardError('Geen geldig akaart nummer');
      return;
    }

    startFormRegistration(true);
  };

  if (form.error && form.error.reason === 'full') {
    isButtonDisabled = true;
  }

  return (
    <React.Fragment>
      <h3>Inschrijven met A-kaart</h3>
      <p className="u-margin-top">Als je wil inschrijven met een A-kaart, moet je verder geen persoonsgegevens invullen.</p>
      <p className="u-margin-top-xs">Heb je recht op kansentarief en schrijf je in voor een betalende activiteit?
          Schrijf dan zeker in met je A-kaart. Zo wordt de korting automatisch toegepast.</p>
      <p className="u-margin-top-xs u-margin-bottom">Gelieve hieronder de A-kaartgegevens in te vullen van de persoon die je inschrijft.</p>
      <div className="box">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-10 u-margin-bottom-xs">
            <TextField
              id={'acard-textfield'}
              name={'acard-textfield'}
              mask={'9999.9999.9999'}
              required={true}
              label={'A-kaartnummer'}
              onChange={e => updateInfant('aCardNumber', e.target.value)}
            />

            {acardError && <span className="u-text-danger">{acardError}</span>}
          </div>
          <div className="col-xs-12 col-sm-12 col-md-10">
            <TextField
              id={'birthdate-textfield'}
              name={'birthdate-textfield'}
              mask={'99/99/9999'}
              required={true}
              label={'Geboortedatum'}
              onChange={e => updateInfant('birthDate', e.target.value)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-6 u-margin-top">
            <Button type="primary" disabled={isButtonDisabled} block onClick={() => validateAcardInfo()}>
              Inschrijving starten
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const WithoutAcardSection = (props) => {
  const { form, startFormRegistration } = props;
  let isButtonDisabled = form.loading;

  if (form.error && form.error.reason === 'full') {
    isButtonDisabled = true;
  }

  return (
    <React.Fragment>
      <h3>Inschrijven zonder A-kaart</h3>
      <p className="u-margin-top">Als je wil inschrijven zonder A-kaart, kan je in de volgende stap alle gevraagde persoonsgegevens invullen.</p>
      <div className="row">
        <div className="col-xs-12 col-md-6 u-margin-top">
          <Button type="primary" block disabled={isButtonDisabled} onClick={() => startFormRegistration(false)}>
            Inschrijven zonder A-kaart
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
};


const ACardSection = (props) => {
  const { activity } = props;

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-xs">
          <div className="box">
            <div className="row">
              <div className="col-xs-12  col-sm-12 col-md-6 u-margin-top">
                <WithAcardSection {...props} />
              </div>
              {!activity.aCardObligated
                && <div className="col-xs-12 col-sm-12 col-md-6 u-margin-top">
                  <WithoutAcardSection {...props} />
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

ACardSection.propTypes = {
  form: PropTypes.object,
  infant: PropTypes.object,
  startFormRegistration: PropTypes.func,
  updateInfant: PropTypes.func,
};

export default ACardSection;
