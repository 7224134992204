import store from 'store';
import queryString from 'query-string';

const STORE_IDENTIFIER_SLUG = 'SPORT_STAD_PAGE';
const STORE_IDENTIFIER_PARAMS = 'SPORT_STAD_PARAMS';
export function saveCurrentPage(slug) {
  if (!slug) {
    return;
  }

  store.set(STORE_IDENTIFIER_SLUG, { slug });
}

export function saveCurrentQueryParams(queryParams) {
  const params = queryParams || {};
  store.set(STORE_IDENTIFIER_PARAMS, params);
}
export function navigateToLatestPage(browseryHistory, withQueryParams) {
  let { slug } = store.get(STORE_IDENTIFIER_SLUG);
  if (!slug) {
    slug = '';
  }
  let path = slug;
  if (withQueryParams) {
    const params = store.get(STORE_IDENTIFIER_PARAMS);
    path += `?${queryString.stringify(params, { arrayFormat: 'comma' })}`;
  }
  return browseryHistory.push(`/${path}`);
}
