import {
  FETCH_ACTIVITY_BEGIN,
  FETCH_ACTIVITY_SUCCESS,
  FETCH_ACTIVITY_FAILURE,
} from 'actions/types';

const initialState = {
  activity: {},
  loading: false,
  error: null,
};

export default function activityReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ACTIVITY_BEGIN:
      return {
        ...state,
        loading: true,
        activity: {},
      };
    case FETCH_ACTIVITY_SUCCESS:
      return {
        ...state,
        loading: false,
        activity: action.payload.activity,
      };
    case FETCH_ACTIVITY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        activity: {},
      };
    default:
      return state;
  }
}
