import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@acpaas-ui/react-components';

const defaultDayPicker = {
  display: true,
  className: 'filter-day-picker',
};

const DayPicker = (props) => {
  const onChange = (name, newValue) => {
    let { values = [] } = props;

    if (!Array.isArray(values)) {
      values = values.split(',');
    }

    if (values.includes(newValue)) {
      values = values.filter(item => item !== newValue);
    } else {
      values.push(newValue);
    }

    props.onChange(name, values);
  };

  const { values = [] } = props;
  let { field } = props;
  field = { ...defaultDayPicker, ...field };

  if (!field.display) {
    return null;
  }

  const CheckBoxElement = field.options.map((day, index) => <Checkbox
    key={index}
    checked={values.includes(day.value)}
    id={`${field.name}-${index}`}
    name={`name-${field.name}-${index}`}
    required={field.required}
    label={day.label}
    placeholder={day.placeholder}
    onChange={() => onChange(field.name, day.value)}
  />);


  return (
    <div className={`${field.className} a-input`} >
      <label className="a-input__label">{field.label}</label>
      {CheckBoxElement}
    </div>
  );
};

DayPicker.propTypes = {
  field: PropTypes.object,
  onChange: PropTypes.func,
  value: PropTypes.any,
};

export default DayPicker;
