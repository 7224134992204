export default function isValidNationalNumber(nationalNumber) {
  if (!nationalNumber) {
    return false;
  }

  nationalNumber = nationalNumber.replace(/\D/g, '');
  const number = nationalNumber.substr(0, 11);

  if (!/\d{11}/.test(number)) {
    return false;
  }

  // Numbers we're going to check against
  let toControl = number.substr(0, 9);

  // Verification number
  const control = number.substr(9, 2);

  // Date parsing
  const date = number.substr(0, 6);
  const dateYear = parseInt(date.substr(0, 2), 10);
  const dateMonth = parseInt(date.substr(2, 2), 10);
  const dateDay = parseInt(date.substr(4, 2), 10);
  const todayYear = parseInt(new Date().getFullYear().toString().substr(2, 2), 10);

  // Simple date check
  if (dateMonth < 1 || dateMonth > 12 || dateDay < 1 || dateDay > 31) {
    return false;
  }

  // Add '2' before if date after 1st Jan 2000
  if (dateYear < todayYear) {
    toControl = `2${toControl.toString()}`;
  }

  // Modulo calculation
  toControl = parseInt(toControl, 10);
  let modulo = 97 - (toControl % 97);
  modulo = Math.floor(modulo);
  modulo = modulo < 10 ? `0${modulo}` : `${modulo}`;


  return control === modulo;
}
