import { Button, Icon, Switch } from '@acpaas-ui/react-components';
import moment from 'moment';
import React from 'react';
import { euroFormat } from 'util/currency';
import './ShoppingCartListItem.scss';

const getPriceAfterCoupon = (originalPrice) => {
  const newPrice = (originalPrice - 2);
  return Math.max(0, newPrice);
};
// TODO PV CHECK IF ALL COUPONS ARE ALREADY USED
const ShoppingCartListItem = ({
  index,
  data = {},
  canApplyCoupon,
  couponApplied,
  onDelete,
  onApplyCoupon,
}) => <div className='m-cart-list-item u-bg-light u-margin-bottom-xs'>
  <div className="m-cart-list-item__content">
    <h6 className="m-cart-list-item__title u-margin-bottom-xs">{data?.activity?.name}</h6>
    <div className='m-cart-list-item__row'>
      <div className='col-xs-12 col-sm-10'>
        <div className='row'>
          <Icon className='u-margin-right-xs' name='calendar' />
          <p>{moment(data?.activity?.activityStart).format('DD/MM/YYYY')} - {moment(data?.activity?.activityEnd).format('DD/MM/YYYY')}</p>
        </div>
        <div className='row u-margin-top-xxs'>
          <Icon className='u-margin-right-xs' name='clock-o' />
          <p>Van {data?.activity?.startTime} tot {data?.activity?.endTime}</p>
        </div>
        <div className='row u-margin-top-xxs'>
          <Icon className='u-margin-right-xs' name='user' />
          <p>{data?.participant?.name}</p>
        </div>
        <div className='row u-margin-top-xs m-cart-list-item__price-mobile'>
          <Icon className='u-margin-right-xs' name='eur' />
          <p>{couponApplied(data?.registrationId) ? getPriceAfterCoupon(data?.selectedPrice) : data?.selectedPrice}</p>
        </div>
        {canApplyCoupon(data) ? <div className='row u-margin-top-xxs m-cart-list-item__coupon-mobile'>
          <Switch
            label={'A-kaart coupon?'}
            id={`checkbox-acard-coupon-mobile-${index}`}
            name={`checkbox-acard-coupon-mobile-${data?.activity?.name}`}
            checked={couponApplied(data?.registrationId)}
            onClick={() => onApplyCoupon(data?.registrationId, data?.acardNumber)}
            disabled
          />
        </div> : null}
      </div>
    </div>
  </div>
  {canApplyCoupon(data) ? <div className='m-cart-list-item__coupon'>
    <Switch
      label={'A-kaart coupon?'}
      id={`checkbox-acard-coupon-${index}`}
      name={`checkbox-acard-coupon-${data?.activity?.name}`}
      checked={couponApplied(data?.registrationId)}
      onClick={() => onApplyCoupon(data?.registrationId, data?.acardNumber)}
    />
  </div> : null}
  <div className=' m-cart-list-item__price'>
    <p>{couponApplied(data?.registrationId) ? euroFormat(getPriceAfterCoupon(data?.selectedPrice)) : euroFormat(data?.selectedPrice)}</p>
  </div>
  <div className='col-xs-1 m-cart-list-item__button'>
    <Button icon="trash" ariaLabel="Verwijderen" transparent outline block type="danger" onClick={() => onDelete(data?.registrationId)}></Button>
  </div>
</div>;
export default ShoppingCartListItem;
