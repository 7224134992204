import React from 'react';
import { RadioGroup } from '@acpaas-ui/react-components';
import bancontact from './assets/bancontact.png';
import maestro from './assets/maestro.png';
import visa from './assets/visa.png';
import mastercard from './assets/mastercard.png';

const paymentMethodLabelWidth = '230px';

const PaymentMethodIcon = props => (
  <img
    src={props.imageSrc}
    alt={props.alt || ''}
    height="24"
    style={{ float: 'right', margin: '0 auto' }}
  />
);

export const defaultPaymentOptions = [
  {
    value: 'Bancontact/Mister Cash',
    label: (
      <span>
        <span
          style={{ width: paymentMethodLabelWidth, display: 'inline-block' }}>
                    Online betaling met Bancontact
        </span>
        <PaymentMethodIcon imageSrc={bancontact} alt="bancontact" />
      </span>
    ),
  },
  {
    value: 'Maestro',
    label: (
      <span>
        <span
          style={{ width: paymentMethodLabelWidth, display: 'inline-block' }}>
                    Online betaling met Maestro
        </span>
        <PaymentMethodIcon imageSrc={maestro} alt="maestro" />
      </span>
    ),
  },
  {
    value: 'VISA',
    label: (
      <span>
        <span
          style={{ width: paymentMethodLabelWidth, display: 'inline-block' }}>
                    Online betaling met VISA
        </span>
        <PaymentMethodIcon imageSrc={visa} alt="visa" />
      </span>
    ),
  },
  {
    value: 'MasterCard',
    label: (
      <span>
        <span
          style={{ width: paymentMethodLabelWidth, display: 'inline-block' }}>
                    Online betaling met MasterCard
        </span>
        <PaymentMethodIcon imageSrc={mastercard} alt="mastercard" />
      </span>
    ),
  },
];

export const banktransferOption = {
  value: 'banktransfer',
  label: (
    <span>
      <span style={{ width: paymentMethodLabelWidth, display: 'inline-block' }}>
                Betaling via bankoverschrijving
      </span>
    </span>
  ),
};

export const PaymentSelection = ({
  showBankTransfer = false,
  setPaymentMethod,
  paymentMethod,
}) => {
  const options = [...defaultPaymentOptions];
  const onChange = (e) => {
    setPaymentMethod(e.target.value);
  };

  if (showBankTransfer) {
    options.push(banktransferOption);
  }

  return (
    <RadioGroup
      options={options}
      label=""
      value={paymentMethod}
      onChange={onChange}
    />
  );
};
