import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@acpaas-ui/react-components';

import RegistrationConditionsCheckbox from './Specials/RegistrationConditionsCheckbox';

const CheckBoxWrapper = (props) => {
  const onChange = (name, value) => {
    props.onChange(name, value);
  };

  const { field, value } = props;

  if (field.name === 'participantConditions') {
    return <RegistrationConditionsCheckbox {...props} />;
  }

  return <Checkbox
    checked={value}
    id={field.name}
    name={`name-${field.name}`}
    className={field.className}
    required={field.required}
    label={field.label}
    placeholder={field.placeholder}
    onChange={() => onChange(field.name, !value)}
  />;
};

CheckBoxWrapper.propTypes = {
  field: PropTypes.object,
  onChange: PropTypes.func,
  value: PropTypes.any,
};

CheckBoxWrapper.defaultProps = {
  field: {},
};

export default CheckBoxWrapper;
