import { AUTH_ACTION_RESET, AUTH_ACTION_SUCCESS, FETCH_USER_FAILURE } from 'actions/types';
import { clearToken, getToken } from 'util/localStorage';

const initialState = {
  authenticated: !!getToken(),
  user: {
    firstName: '',
    lastName: '',
    userName: '',
  },
};

const authentication = (state = initialState, action = {}) => {
  switch (action.type) {
  case AUTH_ACTION_SUCCESS:
    return {
      ...state,
      authenticated: true,
      user: {
        ...action.data,
        firstName: action?.data?.firstName || '',
        lastName: action?.data?.lastName || '',
      },
    };
  case FETCH_USER_FAILURE:
    clearToken();
    return { ...initialState, authenticated: false };
  case AUTH_ACTION_RESET:
    return { ...initialState, authenticated: false };
  default:
    return state;
  }
};

export default authentication;
