import React, { Component } from 'react';
import {
  Steps,
  Step,
  Spinner,
} from '@acpaas-ui/react-components';

import CancelPopup from 'components/Layout/PopUp/CancelPopup';
import { Notifications } from 'components/Layout/Notifications';
import { ConfirmationPopUp } from 'components/Layout/PopUp';
import { ScrollToTop } from 'components/Layout';
import { withRouter } from 'react-router-dom';
import { SingleStep } from './Renderer/Steps';

class FormRenderer extends Component {
  state = {
    current: 0,
    data: {},
    isCancelling: false,
    loading: false,
  }

  componentDidMount() {
    ScrollToTop();
  }

  componentDidUpdate() {
    const { form } = this.props;

    if (form.error) {
      Notifications.error(form.error.message);
    }

    if (form.type === 'submit' && form.result === 'success') {
      ScrollToTop();
    }
  }

  onChange = (index, values) => {
    this.setState({
      data: {
        ...this.state.data,
        [`step-${index}`]: values,
      },
    });
  };

  previous = () => {
    this.setState({ current: this.state.current - 1 });
  };

  next = () => {
    this.setState({ current: this.state.current + 1 });
  };

  navigate = (step) => {
    this.setState({ current: step });
  };

  cancel = () => {
    this.setState({ loading: true });
    this.props.onCancel();
  };

  openCancelpopup = () => {
    this.setState({
      isCancelling: true,
    });
  }

  closeCancelPopup = () => {
    this.setState({
      isCancelling: false,
    });
  }

  renderResponseHandler = () => {
    const {
      form, slug, history,
    } = this.props;
    const { registration = {} } = form;
    if (form.type === 'submit') {
      if (registration.status === 'submitted' && !registration.isFree) {
        let { pathname } = history.location;
        pathname = pathname.replace('inschrijven', 'betalen');
        return this.props.history.push(`${pathname}/${registration.id}`);
      }
      if (form.result === 'success') {
        return <ConfirmationPopUp
          slug={slug}
          isFree={registration.isFree}
          specialNeeds={registration.specialNeeds}
          needsApproval={registration.status === 'waitingApproval'}
        />;
      }
    }


    return null;
  }

  renderCancelPopup = () => {
    if (!this.state.isCancelling) {
      return null;
    }

    return (
      <CancelPopup
        accept={this.cancel}
        decline={this.closeCancelPopup}
        loading={this.state.loading}
      />
    );
  }

  renderSteps = steps => steps.map(step => (<Step key={step.name} title={step.name} />));

  renderStep = () => {
    const { form, isWaitingList, infant } = this.props;
    const { current, child } = this.state;
    const step = form.form.steps[current];

    return <SingleStep
      step={step}
      form={form.form}
      data={this.props.data}
      child={child}
      index={current}
      onChange={values => this.props.onChange(current, values)}
      next={this.next}
      previous={this.previous}
      submit={this.props.onSubmit}
      addToCart={this.props.onAddToCart}
      cancel={this.openCancelpopup}
      isWaitingList={isWaitingList}
      infant={infant}
      navigate={this.navigate}
      allowAcardCoupons={this.props.allowAcardCoupons}
      allowGroupedPurchase={this.props.allowGroupedPurchase}
    />;
  };

  render() {
    const { form } = this.props;
    if (form.type === 'fetch' || form.type === 'start') {
      if (form.loading || !form.form) { return (<Spinner size="large" />); }
    }

    return (
      <React.Fragment>
        {this.renderResponseHandler()}
        {this.renderCancelPopup()}
        <div className="row">
          <div className="col-xs">
            <Steps current={this.state.current}>
              {this.renderSteps(form.form.steps)}
            </Steps>
          </div>
        </div>
        {this.renderStep()}
      </React.Fragment>
    );
  }
}

export default withRouter(FormRenderer);
