const notificationSettings = {
  wrapperId: 'astad-notification-wrapper',
  types: [
    {
      type: 'info',
      icon: 'fa fa-info',
      classList: 'info',
    },
    {
      type: 'error',
      icon: 'fa fa-warning',
      classList: 'error',
    },
    {
      type: 'warning',
      icon: 'fa fa-warning',
      classList: 'warning',
    },
    {
      type: 'success',
      icon: 'fa fa-check',
      classList: 'success',
    },
    {
      type: 'notification',
      icon: 'fa fa-bell-o',
      classList: 'notification',
    },
  ],
};

export { notificationSettings };
