import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ViewField from '../../Fields/ViewField';

class ViewSection extends Component {
  renderFields = (fields) => {
    const { data } = this.props;
    return fields.map(field => (
      <div className="row" key={field.name}>
        <div className="col-xs-12">
          <ViewField
            field={field}
            data={data}
          />
        </div>
      </div>
    ));
  };

  renderSection = (section) => {
    if (section.fields.length === 0) {
      return null;
    }

    return (
      <div>
        <h5>{section.title}</h5>
        {this.renderFields(section.fields)}
      </div>
    );
  }

  render() {
    const { section } = this.props;
    return this.renderSection(section);
  }
}

ViewSection.propTypes = {
  data: PropTypes.object,
  section: PropTypes.object,
};

export default ViewSection;
