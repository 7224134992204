import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import './styles/main.scss';
import App from './App';
import store from './store/configureStore';


async function mountApp() {
  // necessary to create session
  if (process.env.NODE_ENV !== 'production') {
    await fetch('/session');
  }

  ReactDOM.render(
    <BrowserRouter>
      <Provider store={store}><App /></Provider>
    </BrowserRouter>, document.getElementById('root'));
}


mountApp();
