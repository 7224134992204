import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@acpaas-ui/react-components';

class Navigation extends Component {
  renderNext = () => {
    const { next } = this.props;
    return (
      <div className="col-md-4 col-sm-12 col-xs-12 u-margin-top-xs">
        <Button type="primary" htmlType="button" onClick={next}>Volgende stap</Button>
      </div>
    );
  };

  renderPrevious = () => {
    const { previous } = this.props;
    return (
      <div className="col-md-4 col-sm-12 col-xs-12 u-margin-top-xs">
        <Button id="btn-previous-step" type="primary" htmlType="button" onClick={previous} negative>
          Vorige stap
        </Button>
      </div>
    );
  };

  render() {
    const {
      index,
    } = this.props;

    return (
      <div className="row u-margin-top">
        {this.renderNext()}
        {index > 0
          && this.renderPrevious()
        }
      </div>
    );
  }
}

Navigation.propTypes = {
  index: PropTypes.number,
  next: PropTypes.func,
  previous: PropTypes.func,
};

export default Navigation;
