function createType(type) {
  return type.split('_').reverse().pop().toLowerCase();
}

function createResult(result) {
  return result.split('_').pop().toLowerCase();
}


export const Extender = {
  createType,
  createResult,
};

