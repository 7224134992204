import CartOverview from 'containers/Cart/CartOverview';
import { matchPath } from 'react-router-dom';
import Login from 'containers/Auth/Login';
import Logout from 'containers/Auth/Logout';
import {
  Overview,
  Activity,
  Register,
  Pay,
  Registrations,
  Vouchers,
} from './containers';

const defaultPathState = {
  header: {
    menuItems: [
      {
        title: 'Mijn Inschrijvingen',
        url: '/mijn-inschrijvingen',
      },
      {
        title: 'Vorige Inschrijvingen',
        url: '/vorige-inschrijvingen',
      },
    ],
  },
};

const routes = [
  {
    path: '/login',
    componentName: Login,
    state: {
      ...defaultPathState,
    },
  },
  {
    path: '/logout',
    componentName: Logout,
    state: {
      ...defaultPathState,
    },
  },
  {
    path: '/:slug/mijn-inschrijvingen',
    componentName: Registrations,
    state: {
      ...defaultPathState,
    },
  },
  {
    path: '/:slug/mijn-tegoedbonnen',
    componentName: Vouchers,
    state: {
      ...defaultPathState,
    },
  },
  {
    path: '/:slug/activiteit/:code/inschrijven',
    componentName: Register,
    state: {
      ...defaultPathState,
    },
  },
  {
    path: '/:slug/activiteit/:code/betalen/:registrationId',
    componentName: Pay,
    state: {
      ...defaultPathState,
    },
  },
  {
    path: '/:slug/activiteit/:code',
    componentName: Activity,
    state: {
      ...defaultPathState,
    },
  },
  {
    path: '/:slug/winkelmand',
    componentName: CartOverview,
    state: {
      ...defaultPathState,
    },
  },
  {
    path: '/:slug',
    componentName: Overview,
    state: {
      ...defaultPathState,
    },
  },

];

const getCurrentPathState = (location) => {
  const { pathname } = location;
  const match = routes.find((route) => {
    const exact = matchPath(pathname, { path: route.path, exact: true });
    if (exact) {
      return route;
    }
    return null;
  });
  return (match && match.state);
};

export { routes, getCurrentPathState };
