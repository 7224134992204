/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { TextField } from '@acpaas-ui/react-components';

import './PostalCodePicker.css';

const Flyout = ({ children }) => (
  <div className="m-flyout__content">
    {children}
  </div>
);

const ResultRow = ({ onClick, children }) => (
  // eslint-disable-next-line jsx-a11y/no-static-element-interactions
  <div className="result-row-postal-code-picker" onMouseDown={onClick}>
    {children}
  </div>
);

const PostalCodePicker = (props) => {
  const { field, data, value } = props;
  // make a copy of the value, controlled input
  const [postalCodes, setPostalCodes] = useState([]);
  const [selected, setSelected] = useState(`${props.value || ''}`);
  const [isOpen, setIsOpen] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const isBelgian = data.participantCountry === 'BE' || data.debtorCountry === 'BE';

  useEffect(() => {
    fetch('/api/postnames')
      .then(res => res.json())
      .then((response) => {
        setPostalCodes(response);
      })
      .catch(error => console.log(error));
  }, []);

  const onClick = (value) => {
    setSelected(value);
    props.onChange(field.name, value);
    setIsOpen(false);
  };

  const onBlur = (value) => {
    if (isBelgian) {
      if (!selected) {
        return;
      }
      const results = postalCodes.filter(code => code.includes(selected.trim().replace(/\b[a-z]/g, match => match.toUpperCase())));
      let select = selected;
      [select] = results;
      if (!select) {}
      setSelected(select);
      setIsOpen(false);
      props.onChange(field.name, select || '');
      props.onBlur(field.name, select || '');
    } else {
      props.onBlur(field.name, value);
    }
  };

  const onSearch = (value) => {
    setSelected(value);
    if (!value) {
      setIsOpen(false);
      return setSearchResults([]);
    }
    const results = postalCodes.filter(code => code.includes(value.trim().replace(/\b[a-z]/g, match => match.toUpperCase())));
    setIsOpen(results.length > 0);
    setSearchResults(results.slice(0, 10));
  };

  const onChange = (newValue) => {
    if (isBelgian) {
      onSearch(newValue);
    } else {
      props.onChange(field.name, newValue);
    }
  };

  const openClass = isOpen ? 'is-open' : '';
  return (
    <div className={`postal-code-picker m-flyout m-flyout--full ${openClass}`}>
      <TextField
        value={isBelgian ? selected : value}
        id={field.id}
        name={`name-${field.name}`}
        label={field.label}
        {...(isBelgian ? { autocomplete: 'new-password' } : {})}
        placeholder={field.placeholder}
        required={true}
        onChange={e => onChange(e.target.value)}
        onBlur={e => onBlur(e.target.value)}
      />
      {(isOpen && isBelgian) && <Flyout>
        {searchResults
          .map(result => (
            <ResultRow onClick={() => onClick(result)} key={result}>{result}</ResultRow>
          ))}
      </Flyout>}
    </div>
  );
};

export default PostalCodePicker;
