
import { Spinner } from '@acpaas-ui/react-components';
import { fetchUser } from 'actions/Auth';
import { SportStadHeader } from 'components';
import MetaTags from 'components/MetaTags';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  useHistory,
} from 'react-router-dom';
import { storeToken } from 'util/localStorage';
import { getQueryParams } from 'util/queryParams';

function Login() {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = getQueryParams(window.location.search || '');

  useEffect(() => {
    if (params.token && params.token.length > 10) {
      storeToken(params.token);
      dispatch(fetchUser(() => {
        history.push(params?.fromUrl);
      }));
    }
  }, [dispatch, history, params]);

  return <>
        <MetaTags />
        <SportStadHeader
          title={'Aanmelden'}
          backgroundImage={''}
        />
        <div className="main u-wrapper">
          <div className="u-container u-margin-top-lg u-margin-bottom-lg u-text-center" role="main">
            <Spinner size='large' className='u-margin-bottom-lg' />
            <h3>Aanmelden...</h3>
          </div>
        </div>
    </>;
}

export default Login;
