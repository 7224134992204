import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import {
  Card, CardTitle, CardBody, Icon, Button,
} from '@acpaas-ui/react-components';
import DatesFlyoutButton from 'components/DatesFlyoutButton';

import Counter from '../../Counter';
import RegisterButton from '../../RegisterButton';

import './ActivityResultItem.scss';

class ActivityResultItem extends Component {
  render() {
    const {
      overviewFields = [],
      imageUrl = '',
      location = {},
      code = '',
      pageSlug = '',
      name = '',
      wheelchairAccessible = false,
      counter = false,
      allDates,
      type,
    } = this.props.activity;

    const imageStyle = {
      backgroundImage: `url(${imageUrl})`,
    };

    const LeftColumnFields = overviewFields.slice(0, overviewFields.length - 1);

    return (
      <Card className="u-margin-top activity-result-item" style={{ boxSizing: 'border-box' }}>
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-4 activity-image-wrapper">
            <div className="activity-image" style={imageStyle} />
            {counter && <Counter {...this.props.activity} className="activity-counter u-bg-light"/>}
          </div>
          <div className="col-md-8">
            <CardBody>
              <CardTitle>
                <Link to={`/${pageSlug}/activiteit/${code}`}>{name}</Link>
                {wheelchairAccessible && <Icon name={'wheelchair fa-fw'} /> }
              </CardTitle>
              <div className="row">
                <div className="col-xs-12 col-md-6">
                  <ul className="a-list has-icon-left">
                    {LeftColumnFields.map((field, index) => (
                      <li key={index} className="detail-line">
                        <Icon name={`${field.icon} fa-fw`} />
                        <div
                          className="inline-block"
                          dangerouslySetInnerHTML={{ __html: field.text }}
                        />
                        {(type === 'periodieke activiteit' && index === 0) && <DatesFlyoutButton dates={allDates} />}
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="col-xs-12 col-md-6">
                  <ul className="a-list has-icon-left">
                    <li>
                      <Icon name={'map-marker'} />
                      <span className={'inline-block'}>
                        {location.name}
                        <br />
                        {location.street} {location.nr}
                        <br />
                        {location.postalCode} {location.district}
                      </span>
                    </li>
                  </ul>
                  <div className="u-margin-top">
                    <RegisterButton {...this.props.activity} history={this.props.history} />
                    <Button onClick={() => this.props.history.push(`/${pageSlug}/activiteit/${code}`)} negative>Meer lezen</Button>
                  </div>
                </div>
              </div>
            </CardBody>
          </div>
        </div>
      </Card>
    );
  }
}

ActivityResultItem.propTypes = {
  activity: PropTypes.object,
  history: PropTypes.object,
};

export default withRouter(ActivityResultItem);
