/* eslint-disable jsx-a11y/anchor-is-valid */
import { Alert, Pagination, Spinner } from '@acpaas-ui/react-components';
import getPage from 'actions/Pages';
import { fetchRegistrationsHistory } from 'actions/RegistrationsHistory';
import { cancelRegistrationById } from 'api/Registrations';
import { SportStadHeader } from 'components';
import { LoginPopUp } from 'components/Layout/PopUp';
import CancelRegistrationPopUp from 'components/Layout/PopUp/CancelRegistrationPopUp';
import MetaTags from 'components/MetaTags';
import Table from 'components/Table/Table';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { authenticatedSelector } from 'selectors/auth.selectors';
import {
  isLoadingRegistrationsSelector,
  registrationPaginationSelector,
  registrationHistorySelector,
} from 'selectors/registrations.history.selectors';
import { fetchRegistrationsHistorySuccess } from '../../actions/types';

const Registrations = ({ config }) => {
  const dispatch = useDispatch();
  const { slug } = useParams();
  const history = useHistory();
  const [showCancelPopup, setShowCancelPopup] = useState(false);
  const [cancelRegistration, setCancelRegistration] = useState(null);
  const [error, setError] = useState(null);
  const canCancel = config?.CANCEL_REGISTRATIONS;
  const infoUrl = config?.REIMBURSEMENT_COUPON_INFO_URL;
  const [shouldFetch, setShouldFetch] = useState(true);

  const pageInfo = useSelector(state => ({
    loading: state.pageReducer.loading,
    error: state.pageReducer.error,
    data: state.pageReducer.page,
  }));
  const authenticated = useSelector(state => authenticatedSelector(state));
  const registrations = useSelector(state => registrationHistorySelector(state));
  const isLoading = useSelector(state => isLoadingRegistrationsSelector(state));
  const { pagination } = useSelector(state => registrationPaginationSelector(state));

  const formatTableCols = () => [
    {
      label: 'Startdatum Activiteit',
      value: 'activityStartDate',
    },
    {
      label: 'Einddatum Activiteit',
      value: 'activityEndDate',
    },
    {
      label: 'Titel Activiteit',
      value: 'activityName',
    },
    {
      label: 'Deelnemer',
      value: 'participant',
    },
  ];

  const formatTableRows = () => registrations.map(el => ({
    id: el?._id,
    activityStartDate: moment(el?.activityStartDate).format('DD/MM/YYYY'),
    activityEndDate: moment(el?.activityEndDate).format('DD/MM/YYYY'),
    activityName: el?.activityName,
    participant: el?.participant?.name || '',
    canReimburseReason: el?.canReimburseReason,
    canReimburse: !el?.canReimburse,
  }));

  const onUpdatePage = (p) => {
    dispatch(fetchRegistrationsHistory({ page: p, pageSize: pagination.pagesize }));
    setShouldFetch(false);
  };

  const openCancelPopup = (event, rowId) => {
    const registationToCancel = registrations.find(el => el._id === rowId);
    setCancelRegistration(registationToCancel);
    setShowCancelPopup(true);
  };

  const closeCancelPopup = () => {
    setCancelRegistration(null);
    setShowCancelPopup(false);
  };

  const goToOverview = (e) => {
    e.preventDefault();
    history.push(`/${slug}`);
  };

  function setReimbursedRegistrationAsNotReimbursable() {
    const updatedRegistrations = registrations.map((registration) => {
      if (registration?._id === cancelRegistration?._id) {
        registration.canReimburse = false;
      }
      return registration;
    });
    const response = {
      registrations: updatedRegistrations,
      page: pagination?.page,
      pageSize: pagination?.pageSize,
      totalAmount: pagination?.totalValues,
    };
    dispatch(fetchRegistrationsHistorySuccess(response));
  }

  const onCancelRegistration = () => {
    cancelRegistrationById(cancelRegistration._id).then(
      () => {
        setReimbursedRegistrationAsNotReimbursable();
        setError(null);
      },
    ).catch((resp) => {
      setError(<div><p>Er ging iets mis met het annuleren van een inschrijving. Probeer opnieuw</p>
        <br/>
        <p>(Error: <em>{resp.message})</em></p></div>);
    });
    return closeCancelPopup();
  };

  const formatTableActionButtonProps = row => ({
    disabled: row?.canReimburse,
    tooltip: row.canReimburseReason || null,
  });


  const tableActions = () => ({
    label: 'Activiteit Annuleren',
    buttons: [{
      label: 'Annuleren',
      id: 'cancelActivity',
      type: 'secondary',
      iconLeft: 'trash',
      onActionClick: openCancelPopup,
      formatButton: formatTableActionButtonProps,
    }],
  });

  useEffect(() => {
    dispatch(getPage(slug.toLowerCase()));
  }, [dispatch, slug]);

  useEffect(() => {
    if (shouldFetch) {
      dispatch(fetchRegistrationsHistory({ page: pagination.page, pageSize: pagination.pagesize }));
      setShouldFetch(false);
    }
  }, [dispatch, pagination.page, pagination.pagesize, shouldFetch]);

  if (!pageInfo || !pageInfo.data) {
    return null;
  }

  return <>
    <MetaTags/>
    <SportStadHeader
      title={'Mijn inschrijvingen'}
      backgroundImage={pageInfo.data.bannerImage}>
    </SportStadHeader>
    {!authenticated
      && <LoginPopUp slug={slug}/>
    }
    {showCancelPopup
      && <CancelRegistrationPopUp accept={onCancelRegistration} decline={closeCancelPopup} infoUrl={infoUrl}
        registration={cancelRegistration}
      />
    }
    <div className="main u-wrapper">
      <div
        className="u-container u-margin-top-lg u-margin-bottom-lg"
        role="main">
        <div className="row-space-between u-margin-bottom-lg">
          <a onClick={goToOverview} href='#' className="back-to-activities-button">
            <span className="fa fa-chevron-left"></span>
            <span className="back-link"><b>Terug naar het aanbod</b></span>
          </a>
        </div>
        {error && <Alert
          type="danger"
          className='u-margin-bottom'
          title="Opgelet!"
          onClose={() => setError(null)}
          closable={true}>
          {error}
        </Alert>
        }
        <Table
          id={'table-registrations-history'}
          columns={formatTableCols()}
          rows={formatTableRows()}
          actions={canCancel ? tableActions() : null}
          type="primary"
        />
        {isLoading
          ? <div className='u-text-center u-margin'>
            <Spinner/>
          </div>
          : <div className='row u-margin u-align-center '>
            <Pagination
              currentPage={pagination.page}
              itemsPerPage={pagination.pagesize}
              totalValues={pagination.totalValues}
              onUpdate={n => onUpdatePage(n)}
            />
          </div>
        }
      </div>
    </div>
  </>;
};

export default Registrations;
