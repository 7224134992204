import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import { Datepicker } from '@acpaas-ui/react-components';

function getWeekDates(date) {
  const startOfWeek = Moment(date).startOf('isoWeek');
  const endofWeek = Moment(date).day(5);

  const result = [];
  while (startOfWeek.toDate() <= endofWeek.toDate()) {
    result.push(Moment(startOfWeek).format('DD/MM/YYYY'));
    startOfWeek.add(1, 'days');
  }

  return result;
}

const defaultDatePicker = {
  display: true,
  view: 'dayPicker',
};

const WeekPicker = (props) => {
  const onChange = (field, date) => {
    let { values = [] } = props;
    if (!Moment(date, 'DD/MM/YYYY', true).isValid()) {
      return;
    }

    switch (field.view) {
      case 'fullWeek':
        values = getWeekDates(Moment(date, 'DD/MM/YYYY'));
        break;
      case 'singleDay':
        values = [date];
        break;
      default:
        values = (typeof values !== 'undefined' && values instanceof Array) ? values : [];
        if (values.includes(date)) {
          values = values.filter(item => item !== date);
        } else {
          values.push(date);
        }
    }

    props.onChange(field.name, values);
  };

  const { values = [] } = props;
  let { field } = props;
  field = { ...defaultDatePicker, ...field };

  const dates = Array.isArray(values) ? values : [values];

  return (<Datepicker
    key={values}
    selectedDates={dates}
    activeDate={dates[dates.length - 1]}
    id={field.id}
    name={`name-${field.name}`}
    label={field.label}
    required={field.required}
    readOnly={field.readOnly}
    autoClose={field.autoClose}
    noWeekends={field.noWeekends}
    mask={'99/99/9999'}
    format={'DD/MM/YYYY'}
    onChange={date => onChange(field, date)}
  />);
};

WeekPicker.propTypes = {
  field: PropTypes.any,
  onChange: PropTypes.any,
  value: PropTypes.any,
};

export default WeekPicker;
