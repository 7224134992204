import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Button } from '@acpaas-ui/react-components';

import './SideBar.scss';

const SideBar = ({
  open = false,
  children = [],
  onClickOpen,
  onClickClose,
}) => {
  const className = classNames('mapMenuContainer', {
    collapsed: !open,
  });

  return (
    <div className={className}>
      <div className="mapMenuControl">
        {open ? (
          <Button icon="chevron-right" onClick={onClickClose} />
        ) : (
            <Button icon="chevron-left" onClick={onClickOpen} />
          )}
      </div>
      <div className="mapMenuContent">{children}</div>
    </div>
  );
};

SideBar.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  onClickOpen: PropTypes.func.isRequired,
  onClickClose: PropTypes.func.isRequired,
  children: PropTypes.oneOfType(
    [PropTypes.element, PropTypes.arrayOf(PropTypes.element)],
  ),
};

export default SideBar;
