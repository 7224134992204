export const FETCH_PAGE_BEGIN = 'FETCH_PAGE_BEGIN';
export const FETCH_PAGE_SUCCESS = 'FETCH_PAGE_SUCCESS';
export const FETCH_PAGE_FAILURE = 'FETCH_PAGE_FAILURE';

export const FETCH_ACTIVITIES_BEGIN = 'FETCH_ACTIVITIES_BEGIN';
export const FETCH_ACTIVITIES_SUCCESS = 'FETCH_ACTIVITIES_SUCCESS';
export const FETCH_ACTIVITIES_FAILURE = 'FETCH_ACTIVITIES_FAILURE';

export const FETCH_ALL_ACTIVITIES_BEGIN = 'FETCH_ALL_ACTIVITIES_BEGIN';
export const FETCH_ALL_ACTIVITIES_SUCCESS = 'FETCH_ALL_ACTIVITIES_SUCCESS';
export const FETCH_ALL_ACTIVITIES_FAILURE = 'FETCH_ALL_ACTIVITIES_FAILURE';

export const FETCH_ACTIVITY_BEGIN = 'FETCH_ACTIVITY_BEGIN';
export const FETCH_ACTIVITY_SUCCESS = 'FETCH_ACTIVITY_SUCCESS';
export const FETCH_ACTIVITY_FAILURE = 'FETCH_ACTIVITY_FAILURE';

export const START_REGISTRATION_FORM_BEGIN = 'START_REGISTRATION_FORM_BEGIN';
export const START_REGISTRATION_FORM_SUCCESS = 'START_REGISTRATION_FORM_SUCCESS';
export const START_REGISTRATION_FORM_FAILURE = 'START_REGISTRATION_FORM_FAILURE';

export const FETCH_REGISTRATION_FORM_BEGIN = 'FETCH_REGISTRATION_FORM_BEGIN';
export const FETCH_REGISTRATION_FORM_SUCCESS = 'FETCH_REGISTRATION_FORM_SUCCESS';
export const FETCH_REGISTRATION_FORM_FAILURE = 'FETCH_REGISTRATION_FORM_FAILURE';

export const CLEAR_REGISTRATION_FORM = 'CLEAR_REGISTRATION_FORM';

export const SUBMIT_REGISTRATION_FORM_BEGIN = 'SUBMIT_REGISTRATION_FORM_BEGIN';
export const SUBMIT_REGISTRATION_FORM_SUCCESS = 'SUBMIT_REGISTRATION_FORM_SUCCESS';
export const SUBMIT_REGISTRATION_FORM_FAILURE = 'SUBMIT_REGISTRATION_FORM_FAILURE';

export const FETCH_REGISTRATIONS_BEGIN = 'FETCH_REGISTRATIONS_BEGIN';
export const FETCH_REGISTRATIONS_SUCCESS = 'FETCH_REGISTRATIONS_SUCCESS';
export const FETCH_REGISTRATIONS_FAILURE = 'FETCH_REGISTRATIONS_FAILURE';

export const FETCH_COMPLETED_REGISTRATIONS_BEGIN = 'FETCH_COMPLETED_REGISTRATIONS_BEGIN';
export const FETCH_COMPLETED_REGISTRATIONS_SUCCESS = 'FETCH_COMPLETED_REGISTRATIONS_SUCCESS';
export const FETCH_COMPLETED_REGISTRATIONS_FAILURE = 'FETCH_COMPLETED_REGISTRATIONS_FAILURE';

export const DELETE_REGISTRATION_BEGIN = 'DELETE_REGISTRATION_BEGIN';
export const DELETE_REGISTRATION_SUCCESS = 'DELETE_REGISTRATION_SUCCESS';
export const DELETE_REGISTRATION_FAILURE = 'DELETE_REGISTRATION_FAILURE';

export const PAY_REGISTRATIONS_BEGIN = 'PAY_REGISTRATIONS_BEGIN';
export const PAY_REGISTRATIONS_SUCCESS = 'PAY_REGISTRATIONS_SUCCESS';
export const PAY_REGISTRATIONS_FAILURE = 'PAY_REGISTRATIONS_FAILURE';

export const CANCEL_REGISTRATION_FORM_BEGIN = 'CANCEL_REGISTRATION_FORM_BEGIN';
export const CANCEL_REGISTRATION_FORM_SUCCESS = 'CANCEL_REGISTRATION_FORM_SUCCESS';
export const CANCEL_REGISTRATION_FORM_FAILURE = 'CANCEL_REGISTRATION_FORM_FAILURE';

export const FETCH_REGISTRATION_BEGIN = 'FETCH_REGISTRATION_BEGIN';
export const FETCH_REGISTRATION_SUCCESS = 'FETCH_REGISTRATION_SUCCESS';
export const FETCH_REGISTRATION_FAILURE = 'FETCH_REGISTRATION_FAILURE';

export const FETCH_USER_BEGIN = 'FETCH_USER_BEGIN';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';

export const AUTH_ACTION_SUCCESS = 'AUTH_ACTION_SUCCESS';
export const AUTH_ACTION_RESET = 'AUTH_ACTION_RESET';

export const FETCH_REGISTRATION_HISTORY_BEGIN = 'FETCH_REGISTRATION_HISTORY_BEGIN';
export const FETCH_REGISTRATION_HISTORY_SUCCESS = 'FETCH_REGISTRATION_HISTORY_SUCCESS';
export const FETCH_REGISTRATION_HISTORY_FAILURE = 'FETCH_REGISTRATION_HISTORY_FAILURE';

export const FETCH_VOUCHERS_OVERVIEW_BEGIN = 'FETCH_VOUCHERS_OVERVIEW_BEGIN';
export const FETCH_VOUCHERS_OVERVIEW_SUCCESS = 'FETCH_VOUCHERS_OVERVIEW_SUCCESS';
export const FETCH_VOUCHERS_OVERVIEW_FAILURE = 'FETCH_VOUCHERS_OVERVIEW_FAILURE';


export const fetchRegistrationBegin = () => ({
  type: FETCH_REGISTRATION_BEGIN,
});

export const fetchRegistrationFailure = error => ({
  type: FETCH_REGISTRATION_FAILURE,
  error,
});

export const fetchRegistrationSuccess = registration => ({
  type: FETCH_REGISTRATION_SUCCESS,
  registration,
});

export const fetchPageBegin = () => ({
  type: FETCH_PAGE_BEGIN,
});

export const fetchPageSuccess = page => ({
  type: FETCH_PAGE_SUCCESS,
  payload: { page },
});

export const fetchPageFailure = error => ({
  type: FETCH_PAGE_FAILURE,
  payload: { error },
});

export const fetchActivitiesBegin = () => ({
  type: FETCH_ACTIVITIES_BEGIN,
});

export const fetchActivitiesSuccess = activities => ({
  type: FETCH_ACTIVITIES_SUCCESS,
  payload: { activities },
});

export const fetchActivitiesFailure = error => ({
  type: FETCH_ACTIVITIES_FAILURE,
  payload: { error },
});

export const fetchAllActivitiesBegin = () => ({
  type: FETCH_ALL_ACTIVITIES_BEGIN,
});

export const fetchAllActivitiesSuccess = activities => ({
  type: FETCH_ALL_ACTIVITIES_SUCCESS,
  payload: { activities },
});

export const fetchAllActivitiesFailure = error => ({
  type: FETCH_ALL_ACTIVITIES_FAILURE,
  payload: { error },
});

export const fetchActivityBegin = () => ({
  type: FETCH_ACTIVITY_BEGIN,
});

export const fetchActivitySuccess = activity => ({
  type: FETCH_ACTIVITY_SUCCESS,
  payload: { activity },
});

export const fetchActivityFailure = error => ({
  type: FETCH_ACTIVITY_FAILURE,
  payload: { error },
});

export const startRegistrationFormBegin = () => ({
  type: START_REGISTRATION_FORM_BEGIN,
});

export const startRegistrationFormSuccess = () => ({
  type: START_REGISTRATION_FORM_SUCCESS,
  payload: {},
});

export const startRegistrationFormFailure = error => ({
  type: START_REGISTRATION_FORM_FAILURE,
  payload: { error },
});

export const fetchRegistrationFormBegin = () => ({
  type: FETCH_REGISTRATION_FORM_BEGIN,
});

export const fetchRegistrationFormSuccess = form => ({
  type: FETCH_REGISTRATION_FORM_SUCCESS,
  payload: { form },
});

export const fetchRegistrationFormFailure = error => ({
  type: FETCH_REGISTRATION_FORM_FAILURE,
  payload: { error },
});

export const submitRegistrationFormBegin = () => ({
  type: SUBMIT_REGISTRATION_FORM_BEGIN,
});

export const submitRegistrationFormSuccess = registration => ({
  type: SUBMIT_REGISTRATION_FORM_SUCCESS,
  payload: { registration },
});

export const submitRegistrationFormFailure = error => ({
  type: SUBMIT_REGISTRATION_FORM_FAILURE,
  payload: { error },
});

export const fetchRegistrationsBegin = () => ({
  type: FETCH_REGISTRATIONS_BEGIN,
});

export const fetchRegistrationsSuccess = registrations => ({
  type: FETCH_REGISTRATIONS_SUCCESS,
  payload: { registrations },
});

export const fetchRegistrationsFailure = error => ({
  type: FETCH_REGISTRATIONS_FAILURE,
  payload: { error },
});

export const fetchCompletedRegistrationsBegin = () => ({
  type: FETCH_COMPLETED_REGISTRATIONS_BEGIN,
});

export const fetchCompletedRegistrationsSuccess = registrations => ({
  type: FETCH_COMPLETED_REGISTRATIONS_SUCCESS,
  payload: { registrations },
});

export const fetchCompletedRegistrationsFailure = error => ({
  type: FETCH_COMPLETED_REGISTRATIONS_FAILURE,
  payload: { error },
});

export const deleteRegistrationBegin = () => ({
  type: DELETE_REGISTRATION_BEGIN,
});

export const deleteRegistrationSuccess = registration => ({
  type: DELETE_REGISTRATION_SUCCESS,
  payload: { registration },
});


export const startCancelRegistrationForm = () => ({
  type: CANCEL_REGISTRATION_FORM_BEGIN,
});

export const cancelRegistrationFormSuccess = () => ({
  type: CANCEL_REGISTRATION_FORM_SUCCESS,
});

export const cancelRegistrationFormFailure = error => ({
  type: CANCEL_REGISTRATION_FORM_FAILURE,
  payload: { error },
});

export const deleteRegistrationsFailure = error => ({
  type: DELETE_REGISTRATION_FAILURE,
  payload: { error },
});

export const payRegistrationsBegin = () => ({
  type: PAY_REGISTRATIONS_BEGIN,
});

export const payRegistrationsSuccess = () => ({
  type: PAY_REGISTRATIONS_SUCCESS,
  payload: {},
});

export const payRegistrationsFailure = error => ({
  type: PAY_REGISTRATIONS_FAILURE,
  payload: { error },
});

export const fetchUserBegin = () => ({
  type: FETCH_USER_BEGIN,
});

export const fetchUserSuccess = registrations => ({
  type: FETCH_USER_SUCCESS,
  payload: { registrations },
});

export const fetchUserFailure = error => ({
  type: FETCH_USER_FAILURE,
  payload: { error },
});

export const authActionSucces = data => ({
  type: AUTH_ACTION_SUCCESS,
  data,
});

export const fetchUserReset = () => ({
  type: AUTH_ACTION_RESET,
});

export const fetchRegistrationsHistoryBegin = () => ({
  type: FETCH_REGISTRATION_HISTORY_BEGIN,
});

export const fetchRegistrationsHistorySuccess = registrations => ({
  type: FETCH_REGISTRATION_HISTORY_SUCCESS,
  payload: { registrations },
});

export const fetchRegistrationsHistoryFailure = error => ({
  type: FETCH_REGISTRATION_HISTORY_FAILURE,
  payload: { error },
});

export const fetchVouchersOverviewBegin = () => ({
  type: FETCH_VOUCHERS_OVERVIEW_BEGIN,
});

export const fetchVouchersOverviewSuccess = vouchers => ({
  type: FETCH_VOUCHERS_OVERVIEW_SUCCESS,
  payload: { vouchers },
});

export const fetchVouchersOverviewFailure = error => ({
  type: FETCH_VOUCHERS_OVERVIEW_FAILURE,
  payload: { error },
});
