import React from 'react';
import ActivityResultItem from '../ActivityResultItem';

const ActivityResultList = props => (
  props.results.activities.map(
    (activity, index) => <ActivityResultItem key={index} activity={activity}/>,
  )
);

export default ActivityResultList;
