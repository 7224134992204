import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { Icon } from '@acpaas-ui/react-components';
import RegisterButton from '../../../RegisterButton';

class SideBarContent extends Component {
  render() {
    const {
      overviewFields = [],
      imageUrl = '',
      pageSlug = '',
      code = '',
      name = '',
    } = this.props.activity;
    const imageStyle = {
      backgroundImage: `url(${imageUrl})`,
    };

    const LeftColumnFields = overviewFields.slice(0, overviewFields.length - 1);

    return (
      <div id="sidebar-content">
        <div className="col-xs-12 col-sm-12 m-card__image activity-map-image" style={imageStyle} />
        <div className="col-xs-12 col-md-12 u-margin-bottom">
          <h5 className="u-margin-top u-margin-bottom">
            <Link to={`/${pageSlug}/activiteit/${code}`}>{name}</Link>
          </h5>
          <ul className="a-list has-icon-left">
            {LeftColumnFields.map((field, index) => (
              <li key={index}>
                <Icon name={`${field.icon} fa-fw`} />
                <div
                  style={{ display: 'inline-block' }}
                  dangerouslySetInnerHTML={{ __html: field.text }}
                />
              </li>
            ))}
          </ul>
          <hr />
          <RegisterButton {...this.props.activity} history={this.props.history}/>
        </div>
      </div>
    );
  }
}

SideBarContent.propTypes = {
  activity: PropTypes.object,
  history: PropTypes.object,
};

export default withRouter(SideBarContent);
