import React, { Component } from 'react';
import { Button } from '@acpaas-ui/react-components';
import queryString from 'query-string';
import isEmpty from 'lodash/isEmpty';

import SingleField from 'components/FormRenderer/Renderer/Fields/SingleField';
import { withRouter } from 'react-router-dom';
import ActivityTags from './ActivityTags';

import './ActivityFilter.scss';

const ViewModeButtons = ({ onOpen }) => (
  <React.Fragment>
    <div className="col-xs-12 col-sm-4 u-text-right">
      <hr className="mobile-only u-margin-left u-margin-right u-no-margin-top u-no-margin-bottom" />
      <Button type="transparent" iconRight="filter" onClick={onOpen}>
        Filter toevoegen
      </Button>
    </div>
  </React.Fragment>
);

const EditModeButtons = ({ onClose, onApplyFilters, clearFilters }) => (
  <React.Fragment>
    <div className="row">
      <div className="col-xs">
        <hr className="tablet-only u-no-margin-top u-no-margin-bottom" />
      </div>
    </div>
    <div className="row">
      <div className="col-xs end-xs start-sm">
        <button className="a-button a-button--transparent has-icon-right" onClick={clearFilters}>
          <i className="fa fa-times u-text-secondary" />
          Alle velden leegmaken
        </button>
      </div>
      <div className="col-xs-12 col-sm-6 u-text-right">
        <Button type="transparent" iconRight="check" onClick={onApplyFilters}>
          Toepassen
        </Button>
      </div>
    </div>
  </React.Fragment>
);

class ActivityFilter extends Component {
  state = {
    isInEditMode: false,
    data: {},
  };

  componentDidMount = () => {
    const values = queryString.parse(this.props.location.search, { arrayFormat: 'comma' });
    if (values) {
      const urlFilters = {};
      (this.props.filters || []).forEach((filter) => {
        if (values[filter.name]) {
          urlFilters[filter.name] = values[filter.name];
        }
      });
      this.setState({ data: urlFilters });
    }
  }

  toggleState = () => {
    if (this.state.isInEditMode) {
      this.applyFilters();
    }
    this.setState({
      isInEditMode: !this.state.isInEditMode,
    });
  };

  onChange = (name, value) => {
    this.setState(prevState => ({
      data: {
        ...prevState.data,
        [name]: value,
      },
    }));
  };

  renderFilters = () => {
    const { data } = this.state;
    const filters = this.props.filters.map((filter) => {
      if (!isEmpty(filter)) {
        const field = <SingleField
          key={filter.name}
          field={filter}
          data={data}
          onChange={this.onChange}
        />;

        if (field) {
          return (
            <div className="col-xs-12 col-sm-4 activity-filter-field">
              {field}
            </div>
          );
        }
        return field;
      }
    });

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-xs-12 col-sm-4">
            <span className="u-text-bold u-margin-bottom" style={{ display: 'inline-block' }}>Filters:</span>
          </div>
        </div>
        <div className="row">
          {filters}
        </div>
      </React.Fragment>
    );
  }

  applyFilters = () => {
    const newParams = { ...this.state.data, ...{ viewMode: this.props.viewMode } };
    this.updateUrlParams(newParams);
    this.props.onFilter(newParams);
  }

  updateUrlParams = (params) => {
    this.props.history.push({
      pathname: this.props.history.location.pathname,
      search: queryString.stringify(params),
    });
  }

  renderViewMode = filters => (
    <div className="col-xs-12 col-sm-8">
      <span className="u-text-bold u-margin-left" style={{ display: 'inline-block', lineHeight: '3rem' }}>
          Filters:
      </span>
      {isEmpty(filters)
          && <span className="u-text-italic u-margin-left" style={{ display: 'inline-block' }}>
            Er zijn momenteel geen filters actief.
          </span>
      }
      <div className="u-margin-left" style={{ display: 'inline-block' }}>
        <ActivityTags filtersList={this.props.filters} filters={filters} removeTag={key => this.removeTag(key)} />
      </div>
    </div>
  )

  removeTag = (key) => {
    const data = Object.assign({}, this.state.data);
    delete data[key];
    this.setState({ data }, () => this.applyFilters());
  }

  clearFilters = () => {
    const data = Object.assign({}, this.state.data);
    for (const [name] of Object.entries(data)) {
      const currentFilter = this.props.filters.find(filter => filter.name === name);
      switch (currentFilter.type) {
      case 'select':
        data[name] = currentFilter.placeholder || 'Gelieve een keuze te maken';
        break;
      case 'checkbox':
        data[name] = false;
        break;
      case 'textfield':
        data[name] = '';
        break;
      default:
        data[name] = '';
        break;
      }
    }

    this.setState({ data }, () => this.setState({ data: {} }));
  }

  render() {
    const { isInEditMode, data } = this.state;
    return (
      <div className="sportstad-filters row">
        <div className="col-xs">
          <div className="u-bg-light">
            <div className="row middle-xs">
              {isInEditMode ? (
                <div className="grid-box">
                  <div className="col-xs-12">
                    {this.renderFilters()}
                  </div>
                  <div className="col-xs-12 u-margin-top">
                    <EditModeButtons
                      onClose={() => {
                        this.toggleState();
                      }}
                      onApplyFilters={() => {
                        this.applyFilters();
                      }}
                      clearFilters={() => {
                        this.clearFilters();
                      }}
                    />
                  </div>
                </div>
              ) : (
                <React.Fragment>
                  {this.renderViewMode(data)}
                  <ViewModeButtons onOpen={() => this.toggleState()} />
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ActivityFilter);
