import React from 'react';
import { FlyoutButton } from '@acpaas-ui/react-components';

function formatDate(date) {
  const [yyyy, mm, dd] = date.split('-');
  return `${dd}-${mm}-${yyyy}`;
}


function buildDateString({
  startDate, startTime, endDate, endTime,
}) {
  let representation = `${formatDate(startDate)}`;
  if (endDate) {
    representation += ` - ${formatDate(endDate)}`;
  }

  if (startTime) {
    representation += ` van ${startTime}`;
  }

  if (endTime) {
    representation += ` tot ${endTime}`;
  }

  return representation;
}

const DatesFlyoutButton = ({ dates = [] }) => {
  if (dates.length === 0) {
    return null;
  }

  return (
    <FlyoutButton icon="info-circle" size="tiny" className="u-margin-left-xs" flyoutSize="small">
      {dates.map(date => <div className="dateline" key={date.startDate}>
        {buildDateString(date)}
      </div>)}
    </FlyoutButton>
  );
};


export default DatesFlyoutButton;
