/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams, useLocation, withRouter } from 'react-router-dom';
import {
  Textarea,
  Card,
  CardBody,
  Checkbox,
  Alert,
} from '@acpaas-ui/react-components';

import { beginRegistrationForm } from 'actions/RegistrationForm';
import { Notifications } from 'components/Layout/Notifications';
import useDidUpdate from 'hooks/useDidUpdate';

import { LoginPopUp } from 'components/Layout/PopUp';
import ACardSection from './ACardSection';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const IntroError = ({ message }) => (
  <div className="row">
    <div className="col-xs-12 col-md-12 u-margin-bottom subscribe-overview-button">
      <Alert closable={false} type="danger">
        {message}
      </Alert>
    </div>
  </div>
);

const IntroStep = ({
  activity,
  form,
  infant,
  aCardActive,
  updateInfant,
  startFormRegistration,
  isLoggedIn,
  ...props
}) => {
  const { code, slug } = useParams();
  const [specialNeedsInfoRequired, setSpecialNeedsInfoRequired] = useState(false);

  const query = useQuery();
  const type = query.get('type');

  useEffect(() => {
    props.beginRegistrationForm(code, type);
  }, [code]);

  useDidUpdate(() => {
    if (form.error && form.type === 'fetch') {
      Notifications.error(form.error.message);
    }
  });

  const validateInfant = (valid) => {
    if (infant.hasSpecialNeeds && !infant.specialNeedsInfo) {
      setSpecialNeedsInfoRequired(true);
    } else {
      startFormRegistration(valid);
    }
  };

  return (
    <React.Fragment>
      {!isLoggedIn
        && <LoginPopUp slug={slug}/>
      }
      <div className="row">
        <div className="col-xs-12 col-md-9 u-margin-bottom subscribe-overview-button">
          <h2 className="h2 u-margin-bottom">Inschrijven</h2>
        </div>
      </div>

      {form.error && <IntroError {...form.error} />}

      {(activity.inclusive || activity.hasFosterCare) && <div className="row">
        <div className="col-xs">
          <Card>
            <CardBody>
              <h3 className="h6 u-margin-bottom-xs">Heb je …</h3>
              {activity.inclusive
                && <Checkbox
                  id={'special-needs-checkbox'}
                  name={'special-needs-checkbox'}
                  checked={infant.hasSpecialNeeds}
                  className={'special-needs-checkbox'}
                  label={'Nood aan extra zorg (omwille van een beperking, gedragsproblemen, …)?'}
                  onChange={() => updateInfant('hasSpecialNeeds', !infant.hasSpecialNeeds)}
                />
              }
              {activity.hasFosterCare
                  && <Checkbox
                    id={'foster-care-checkbox'}
                    name={'foster-care-checkbox'}
                    checked={infant.hasFosterCare}
                    className={'foster-care-checkbox'}
                    label={'Recht op pleegzorg?'}
                    onChange={() => updateInfant('hasFosterCare', !infant.hasFosterCare)}
                  />
              }
              {infant.hasSpecialNeeds
                  && <Textarea
                    id={'special-needs-textfield'}
                    required
                    name={'special-needs-textfield'}
                    className={'special-needs-textfield'}
                    label={'Geef hieronder meer uitleg over de extra zorg(en) die je kind nodig heeft. Dan kunnen wij nagaan of we deze extra zorg kunnen bieden voor de gekozen Antwerpse Sportweek. Na goedkeuring ontvang je van ons een e-mailbevestiging, zodat je je aanvraag kan vervolledigen en betalen. Anders contacteren we je telefonisch om de mogelijkheden samen te bespreken.'}
                    onChange={e => updateInfant('specialNeedsInfo', e.target.value)}
                  />
              }
              {specialNeedsInfoRequired && <small className="u-text-danger">Dit is een verplicht veld</small>}
            </CardBody>
          </Card>
        </div>
      </div>
      }

      {aCardActive
        && <ACardSection
          activity={activity}
          form={form}
          infant={infant}
          startFormRegistration={validateInfant}
          specialNeedsInfoRequired={specialNeedsInfoRequired}
          updateInfant={updateInfant}
        />
      }
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  form: {
    loading: state.registrationForm.loading,
    error: state.registrationForm.error,
    type: state.registrationForm.type,
  },
});

const mapDispatchToProps = dispatch => ({
  beginRegistrationForm: (code, type) => dispatch(beginRegistrationForm(code, type)),
});

IntroStep.propTypes = {
  aCardActive: PropTypes.bool,
  beginRegistrationForm: PropTypes.func,
  infant: PropTypes.object,
  form: PropTypes.any,
  isLoggedIn: PropTypes.bool,
  match: PropTypes.any,
  startFormRegistration: PropTypes.func,
  updateInfant: PropTypes.func,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(IntroStep);
