
import React from 'react';
import {
  Button,
} from '@acpaas-ui/react-components';

const CancelPopup = ({ accept = () => {}, decline = () => {}, loading = false }) => (
  <div className="astad-login-popup">
    <div className="m-overlay is-active">
      <div className="m-overlay__inner allow-anonymous">
        <div className="m-modal__header">
          <span><b>Inschrijving annuleren?</b></span>
        </div>
        <div className="m-modal__content">
          <p>Ben je zeker dat je je inschrijving wilt annuleren?</p>
          <p>Jouw ingenomen plek komt dan terug vrij.</p>

          <Button className='u-margin-top' type="primary" htmlType="button" onClick={() => accept() } outline block disabled={loading}>
              Ja
          </Button>
          <Button className='u-margin-top' type="primary" htmlType="button" onClick={() => decline()} disabled={loading} block>Nee</Button>
        </div>
      </div>
    </div>
  </div>
);

export default CancelPopup;
