import {
  FETCH_REGISTRATION_BEGIN,
  FETCH_REGISTRATION_SUCCESS,
  FETCH_REGISTRATION_FAILURE,
  SUBMIT_REGISTRATION_FORM_SUCCESS,
} from 'actions/types';


const initialState = {
  registrations: {},
  loading: false,
  error: null,
};

export default function registrationsReducers(state = initialState, action) {
  const { registration, payload = {} } = action;
  switch (action.type) {
    case FETCH_REGISTRATION_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_REGISTRATION_SUCCESS:
      return {
        registrations: { ...state.registrations, [registration.id]: registration },
        loading: false,
        error: null,
      };
    case FETCH_REGISTRATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case SUBMIT_REGISTRATION_FORM_SUCCESS:
      return {
        registrations: { ...state.registrations, [payload.registration.id]: payload.registration },
        loading: false,
        error: null,
      };
    default:
      return state;
  }
}
