import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@acpaas-ui/react-components';

import './RegistrationConditionsCheckbox.css';

const RegistrationConditionsCheckbox = (props) => {
  const onChange = (name, value) => {
    props.onChange(name, value);
  };

  const { field, value } = props;

  const {
    text = 'Ja, ik ga akkoord met de <a target="_blank" href="https://www.antwerpen.be/info/5e4a997973852727a268d3e3/antwerpse-sportweken-algemene-voorwaarden">inschrijvingsvoorwaarden</a>',
  } = field;

  return (
    <div className="registration-conditions-checkbox">
      <Checkbox
        checked={value}
        id={field.name}
        name={`name-${field.name}`}
        className={field.className}
        required={field.required}
        label={text}
        onChange={() => onChange(field.name, !value)}
      />
    </div>
  );
};

RegistrationConditionsCheckbox.propTypes = {
  field: PropTypes.object,
  onChange: PropTypes.func,
  value: PropTypes.any,
};

RegistrationConditionsCheckbox.defaultProps = {
  field: {},
};

export default RegistrationConditionsCheckbox;
