import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Alert } from '@acpaas-ui/react-components';

const WarningCheck = (props) => {
  const onChange = (name, value) => {
    props.onChange(name, value);
  };

  const { field, value } = props;

  return <Alert
    type="warning"
    closable={false}>
      <p className="u-padding u-margin-bottom" dangerouslySetInnerHTML={{ __html: field.description }}></p>
      <Checkbox
        checked={value}
        id={field.name}
        name={`name-${field.name}`}
        className={field.className}
        required={field.required}
        label={field.label}
        placeholder={field.placeholder}
        onChange={() => onChange(field.name, !value)}
      />
    </Alert>;
};

WarningCheck.propTypes = {
  field: PropTypes.object,
  onChange: PropTypes.func,
  value: PropTypes.any,
};

WarningCheck.defaultProps = {
  field: {},
};

export default WarningCheck;
