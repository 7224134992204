import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';

import { notificationSettings } from './settings';

class Notification extends Component {
  removeNotification = () => {
    const target = document.getElementById(notificationSettings.wrapperId);
    ReactDOM.unmountComponentAtNode(target);
  }

  render() {
    const { type, message } = this.props;

    const notificationType = notificationSettings.types.find(messageType => messageType.type === type);

    const statusType = classNames(
      'o-statusbar',
      notificationType.type,
    );

    const iconType = classNames(
      'o-statusbar__status',
      notificationType.icon,
    );

    setTimeout(() => {
      this.removeNotification();
    }, 5000);

    return (
      <div className={statusType}>
        <span className={iconType}></span>

        <div className="o-statusbar__notification">
          <p>
            <span dangerouslySetInnerHTML={{ __html: message }}></span>
          </p>
        </div>

        <button className="a-button has-icon" onClick={() => this.removeNotification()}>
          <span className="fa fa-times"></span>
        </button>
      </div>
    );
  }
}

export default Notification;
