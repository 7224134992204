import React, { Component } from 'react';
import { BasicMap, MarkerLayer } from '@astad20/ui-react';
import { Spinner } from '@acpaas-ui/react-components';

import Sidebar from './SideBar/SideBar';
import SideBarContent from './SideBar/SideBarContent';

import './ActivityResultMap.scss';

class ActivityResultMap extends Component {
  state = {
    activeActivity: null,
    isMenuOpen: false,
  }

  componentDidMount = () => {
    this.props.onMapEnter();
  }

  onClickMarker = (marker) => {
    this.setState({
      activeActivity: marker.activity,
      isMenuOpen: true,
    });
  }

  toggleMenu = () => {
    this.setState({ isMenuOpen: !this.state.isMenuOpen });
  };

  renderMarkers = data => data.reduce((result, activity) => {
    if (activity && activity.location.lat && activity.location.lng) {
      return result.concat({
        name: activity.name,
        activity,
        position: [
          activity.location.lat,
          activity.location.lng,
        ],
      });
    }
    return result;
  }, []);

  render() {
    const { activeActivity, isMenuOpen } = this.state;
    const { allActivities } = this.props;

    if (allActivities.loading) return <Spinner size="large" />;


    return (
      <div id="activities-basic-map-container" className="u-margin-top">
		{allActivities.data && <BasicMap>
		<MarkerLayer markers={this.renderMarkers(allActivities.data)} onClickMarker={marker => this.onClickMarker(marker)} markerIcon={'https://www.antwerpen.be/assets/stadsplan/gfx/markers/buitenschools-vakantieaanbod.svg'} />
    </BasicMap>
    }
        {activeActivity
          && <Sidebar
            onClickClose={this.toggleMenu}
            onClickOpen={this.toggleMenu}
            open={isMenuOpen}>
            <SideBarContent activity={activeActivity} />
          </Sidebar>
        }
      </div>
    );
  }
}

export default ActivityResultMap;
