/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import {
  useHistory, useLocation, useParams, withRouter,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { SportStadHeader } from 'components/Layout/SportStadHeader';
import MetaTags from 'components/MetaTags';
import getPage from 'actions/Pages';
import { navigateToLatestPage } from 'util/links';
import { clearLocalStorage, getFromLocalStorage, saveToLocalStorage } from 'util/localStorage';
import ShoppingCartList from 'components/ShoppingCart/ShoppingCartList/ShoppingCartList';
import { fetchRegistrationsWithPaymentInfo, registrationsCheckout, removeRegistration } from 'api/Registrations';
import { euroFormat } from 'util/currency';
import { Alert, Button } from '@acpaas-ui/react-components';
import PayGrouped from 'containers/Pay/PayGrouped';
import { LoginPopUp } from 'components/Layout/PopUp';
import { authenticatedSelector } from 'selectors/auth.selectors';
import _ from 'lodash';
import CancelPopup from 'components/Layout/PopUp/CancelPopup';

const CartError = error => (
  <Alert type="danger" className="u-margin-bottom u-margin-top">
    {error}
  </Alert>
);

const RegistrationsError = data => (
  data.length ? <Alert type="danger" className="u-margin-bottom u-margin-top">
    <p>Onderstaande inschrijvingen werden uit de winkeland verwijderd omwille van een probleem. </p>
    <ul className='u-margin-top'>
      {data.map((el, index) => <li key={`registration-error-${index}`}> {el?.name} - ({el?.reason})</li>)}
    </ul>
  </Alert> : null
);

const CartOverview = ({ config }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { slug } = useParams();
  const query = new URLSearchParams(useLocation().search);
  const paymentError = query.get('error');
  const paymentSuccessful = query.get('payment') === 'accepted';
  const [cartContent, setCartContent] = useState([]);
  const [bankTransfer, setBankTransfer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState(false);
  const [error, setError] = useState('');
  const [errorRegistrations, setErrorRegistrations] = useState('');
  const [shouldFetch, setShouldFetch] = useState(true);
  const [pay, setPay] = useState(!!paymentError || !!paymentSuccessful);
  const [aCardInfo, setACardInfo] = useState(null);
  const [appliedCoupons, setAppliedCoupons] = useState([]);
  const [vouchersData, setVouchersData] = useState([]);
  const totalVoucherAmount = _.sumBy(vouchersData, 'voucherAvailableValue') || 0;
  const [openCancelPopUp, setOpenCancelPopUp] = useState(false);
  const allowAcardCoupons = !!config?.A_CARD_COUPONS && !!config?.CANCELLED_REGISTRATIONS_VOUCHERS;

  // TODO WOUTER: calculate resting amount here
  const authenticated = useSelector(state => authenticatedSelector(state));
  const page = useSelector(state => ({
    loading: state.pageReducer.loading,
    error: state.pageReducer.error,
    data: state.pageReducer.page,
  }));

  const goToOverview = (e) => {
    e.preventDefault();
    navigateToLatestPage(history, true);
  };

  const goToPay = () => {
    setPay(true);
  };

  const checkoutWithVouchers = () => {
    const checkoutFormat = {
      registrations: cartContent.map((el) => {
        const appliedCoupon = appliedCoupons.find(coupon => coupon.registrationId === el.registrationId);
        return appliedCoupon ? { id: el.registrationId, coupon: appliedCoupon.chequeId } : { id: el.registrationId };
      }),
      paymentMethod: 'voucher',
    };
    registrationsCheckout(checkoutFormat).then(() => {
      history.push(`/${slug}/winkelmand?payment=accepted`);
      return window.location.reload(true);
    }).catch(() => setError('Er is een probleem opgetreden bij het afrekenen.'));
  };

  const goToCart = () => {
    setPay(false);
  };

  useEffect(() => {
    // setLoading(true);
    dispatch(getPage(slug.toLowerCase()));
  }, [dispatch, slug]);


  useEffect(() => {
    const fetchRegistrations = async ids => fetchRegistrationsWithPaymentInfo({ registrationIds: ids })
      .then(({
        registrations, banktransferPossible, aCards, vouchers,
      }) => {
        setErrorRegistrations(registrations.filter(el => el.status !== 200).map(el => ({ name: el?.activity?.name, reason: el?.reason })));
        setCartContent(registrations.filter(el => el.status === 200));
        setBankTransfer(banktransferPossible);
        setACardInfo(aCards);
        setVouchersData(vouchers);
        saveToLocalStorage(`CART_${slug.toUpperCase()}`, registrations.filter(el => el.status === 200).map(el => el?.registrationId));
        setShouldFetch(false);
        setLoading(false);
      })
      .catch(() => {
        setError('Er ging iets mis tijdens het ophalen van de winkelmand.');
        setShouldFetch(false);
        clearLocalStorage(`CART_${slug.toUpperCase()}`);
        setLoading(false);
      });

    const registrationIds = getFromLocalStorage(`CART_${slug.toUpperCase()}`);
    if (shouldFetch && registrationIds) {
      setLoading(true);
      fetchRegistrations(registrationIds);
    }
  }, [loading, shouldFetch, slug, setLoading]);

  const toggleCancelPopup = (id) => {
    setOpenCancelPopUp(id);
  };

  const removeItemFromCart = (id) => {
    const registrationIds = getFromLocalStorage(`CART_${slug.toUpperCase()}`);
    removeRegistration(id).then(() => {
      saveToLocalStorage(`CART_${slug.toUpperCase()}`, registrationIds.filter(el => el !== id));
      setShouldFetch(true);
    }).catch(() => setError('Er ging iets mis bij het verwijderen van de inschrijving. Probeer opnieuw.'));
    setOpenCancelPopUp(false);
  };

  const getTotalCartPrice = () => {
    let totalPrice = 0;
    cartContent.map(
      (cartItem) => {
        if (appliedCoupons.find(el => el.registrationId.toString() === cartItem.registrationId.toString())) {
          totalPrice += Math.max(0, cartItem.selectedPrice - 2);
        } else {
          totalPrice += cartItem.selectedPrice;
        }
      },
    );
    return totalPrice;
  };

  const getTotalPrice = () => {
    const totalPrice = getTotalCartPrice();
    return Math.max(0, totalPrice - totalVoucherAmount);
  };

  const hasCouponApplied = registrationId => !!appliedCoupons.find(el => el.registrationId.toString() === registrationId.toString());

  const getAvailableCouponsForAcard = (aCardNumber) => {
    const aCardCoupons = aCardInfo.find(el => el?.cardNumber?.toString() === aCardNumber?.toString())?.coupons;
    return aCardCoupons.filter(el => !appliedCoupons.find(item => item.chequeId === el.chequeId));
  };

  const unApplyCoupon = (registrationId) => {
    console.log(appliedCoupons);
    setAppliedCoupons(appliedCoupons.filter(coupon => coupon.registrationId.toString() !== registrationId.toString()));
  };

  const applyCoupon = (registrationId, aCardNumber) => {
    const availableCoupons = getAvailableCouponsForAcard(aCardNumber);
    if (hasCouponApplied(registrationId)) {
      return unApplyCoupon(registrationId);
    }
    return !!availableCoupons.length && setAppliedCoupons([...appliedCoupons, { registrationId, chequeId: availableCoupons[0].chequeId }]);
  };

  const allCouponsUsedCheck = (coupons) => {
    const intersection = coupons?.map(el => el.chequeId).filter(element => appliedCoupons?.map(el => el.chequeId).includes(element));
    return (coupons?.length === intersection?.length);
  };

  const canApplyCouponOnItem = item => (allowAcardCoupons
    ? (aCardInfo?.length ? !!aCardInfo.find(coupon => coupon?.cardNumber?.toString() === item?.acardNumber?.toString()) : false) && !allCouponsUsedCheck(aCardInfo?.length ? aCardInfo.find(coupon => coupon?.cardNumber?.toString() === item?.acardNumber?.toString())?.coupons : []) || !!appliedCoupons.find(el => el.registrationId === item.registrationId)
    : false);

  const renderTotalVoucherAmountLine = () => (totalVoucherAmount ? <div className='m-cart-list__total u-margin-top'>
    <div className='m-cart-list-item__content '>
      <p className='m-cart-list-item__total m-cart-list-item__subtitle u-margin-right-xs'>Aprofiel tegoed: </p>
    </div>
    <div className='m-cart-list-item__price'> <p> -{euroFormat(totalVoucherAmount)}</p></div>
    <div className="col-xs-1">
    </div>
  </div> : null);

  const renderAvailableAmountLine = () => (totalVoucherAmount ? <div className='m-cart-list__total u-margin-top-xs'>
    <div className='m-cart-list-item__content '>
      <p className='m-cart-list-item__total m-cart-list-item__subtitle u-margin-right-xs'>Aprofiel restant tegoed na afrekenen: </p>
    </div>
    <div className='m-cart-list-item__price'> <p> {euroFormat(Math.max(0, totalVoucherAmount - getTotalCartPrice()))}</p></div>
    <div className="col-xs-1">
    </div>
  </div> : null);

  const renderTotalPriceLine = () => <div className='m-cart-list__total u-margin-top'>
    <div className='m-cart-list-item__content'>
      <h5 className='m-cart-list-item__total u-margin-right-xs'>Totaal te betalen: </h5>
    </div>
    <h5 className='m-cart-list-item__total-price'> {euroFormat(getTotalPrice())}</h5>
    <div className="col-xs-1">
    </div>
  </div>;

  const renderShoppingCart = () => <div className="u-container u-margin-top-lg u-margin-bottom-lg" role="main">
    <div className="row-space-between u-margin-bottom">
      <a onClick={goToOverview} href='#' className="back-to-activities-button">
        <span className="fa fa-chevron-left"></span>
        <span className="back-link"><b>Terug naar het aanbod</b></span>
      </a>
    </div>
    <div className='row u-margin-top-lg'>
      <div className="col-xs">
        <h2>Overzicht inschrijvingen</h2>
      </div>
    </div>
    {error ? CartError(error) : null}
    {errorRegistrations ? RegistrationsError(errorRegistrations) : null}
    <ShoppingCartList
      aCard={aCardInfo}
      items={cartContent}
      onDelete={toggleCancelPopup}
      onApplyCoupon={applyCoupon}
      loading={loading}
      hasCouponApplied={hasCouponApplied}
      appliedCoupons={appliedCoupons}
      canApplyCouponOnItem={canApplyCouponOnItem}
    />
    {!loading && cartContent.length
      ? <>
        {renderTotalVoucherAmountLine()}
        {renderAvailableAmountLine()}
        {renderTotalPriceLine()}
      </>
      : null}
    <div className='m-cart-list__pay u-margin-top'>
      {getTotalPrice() === 0
        ? <Button disabled={!cartContent.length} onClick={checkoutWithVouchers}>Inschrijvingen afrekenen</Button>
        : <Button disabled={!cartContent.length} onClick={goToPay}>Verder naar betalen</Button>}
    </div>
  </div>;

  const renderPay = () => <div className="u-container u-margin-top-lg u-margin-bottom-lg" role="main">
    {(!paymentSuccessful && !paymentInfo) && <div className="row-space-between u-margin-bottom">
      <a onClick={goToCart} href='#' className="back-to-activities-button">
        <span className="fa fa-chevron-left"></span>
        <span className="back-link"><b>Terug naar winkelmand</b></span>
      </a>
    </div>}
    <div className='row u-margin-top-lg'>
      <div className="col-xs">
        <h2>Betalen</h2>
      </div>
    </div>
    <PayGrouped
      banktransferPossible={bankTransfer}
      registrations={cartContent}
      appliedCoupons={appliedCoupons}
      paymentError={paymentError}
      paymentSuccessful={paymentSuccessful}
      goToOverview={goToOverview}
      setError={setError}
      setShouldFetch={setShouldFetch}
      updatePaymentInfo={setPaymentInfo}
      totalPrice={getTotalPrice()}
    />
  </div>;

  if (!page || !page.data) { return null; }

  return (
    <React.Fragment>
      <MetaTags />
      <SportStadHeader
        title={'Winkelmand'}
        backgroundImage={page.data.bannerImage}>
      </SportStadHeader>
      {!authenticated
        && <LoginPopUp slug={slug} />
      }
      {openCancelPopUp
        && <CancelPopup accept={() => removeItemFromCart(openCancelPopUp)} decline={() => setOpenCancelPopUp(false)} />
      }
      <div className="main u-wrapper">
        {pay ? renderPay() : renderShoppingCart()}
      </div>
    </React.Fragment>
  );
};

export default withRouter(CartOverview);
