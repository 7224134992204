import { FETCH_VOUCHERS_OVERVIEW_BEGIN, FETCH_VOUCHERS_OVERVIEW_FAILURE, FETCH_VOUCHERS_OVERVIEW_SUCCESS } from 'actions/types';

const initialState = {
  vouchers: [],
  loading: false,
  error: null,
  pagination: {
    page: 1,
    pagesize: 10,
    totalValues: 0,
  },
};


export default function vouchersReducer(state = initialState, action) {
  switch (action.type) {
  case FETCH_VOUCHERS_OVERVIEW_BEGIN:
    return {
      ...state, loading: true,
    };
  case FETCH_VOUCHERS_OVERVIEW_SUCCESS:
    return {
      ...state,
      vouchers: action.payload.vouchers.vouchers,
      loading: false,
      pagination: {
        page: action.payload.vouchers.page,
        pagesize: action.payload.vouchers.pageSize,
        totalValues: action.payload.vouchers.totalAmount,
      },
    };
  case FETCH_VOUCHERS_OVERVIEW_FAILURE:
    // TODO PV ERROR HANDLING
    return { ...state, loading: false, error: action.payload.error };
  default:
    return state;
  }
}
