import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { Spinner } from '@acpaas-ui/react-components';
import isEqual from 'is-equal';
import getPage from 'actions/Pages';
import { getActivities, getAllActivities } from 'actions/Activities';
import { saveCurrentPage, saveCurrentQueryParams } from 'util/links';
import { ActivityResult } from 'components/ActivityResult';
import { SportStadHeader } from 'components/Layout/SportStadHeader';
import ActivityFilterHardcoded from 'components/ActivityResult/ActivityFilterHardcoded';
import MetaTags from 'components/MetaTags';
import ShoppingCartButton from 'components/ShoppingCart/ShoppingCartButton/ShoppingCartButton';
import { getFromLocalStorage } from 'util/localStorage';

const defaultParams = {
  page: 1,
  pagesize: 10,
  sort: 'date',
};

let prevQueryParams = {};

class Overview extends Component {
  componentDidMount() {
    const values = queryString.parse(this.props.location.search, { arrayFormat: 'comma' });
    ['viewMode', 'tdtoken', 'tdToken'].forEach(key => delete values[key]);
    prevQueryParams = { ...values };


    let params = values;
    if (!values.page || !values.pagesize) {
      this.updateUrlParams({ ...defaultParams, ...values });
      params = { ...defaultParams, ...values };
    }


    const slug = this.props.match.params.slug.toLowerCase();

    saveCurrentPage(slug);
    saveCurrentQueryParams(params);
    this.props.getPage(slug);
    this.props.getActivities(slug, params);
  }

  componentDidUpdate(prevProps) {
    if (this.props.activities.loading) {
      return;
    }
    const newSlug = this.props.match.params.slug.toLowerCase();
    const oldSlug = prevProps.match.params.slug.toLowerCase();
    if (!oldSlug !== newSlug) {
      saveCurrentPage(newSlug);
    }

    const values = queryString.parse(this.props.location.search, { arrayFormat: 'comma' });
    ['viewMode', 'tdtoken', 'tdToken'].forEach(key => delete values[key]);
    if (!isEqual(prevQueryParams, values)) {
      prevQueryParams = values;
      this.props.getActivities(newSlug, values);
    }
  }

  changePage = (number) => {
    const values = queryString.parse(this.props.location.search);
    const params = values;
    params.page = number;
    this.updateUrlParams(params);

    // this.props.getActivities(this.props.match.params.slug, params);
  }

  changeSort = (sort) => {
    const params = queryString.parse(this.props.location.search);
    params.sort = sort;
    this.updateUrlParams(params);
    // this.props.getActivities(this.props.match.params.slug, params);
  }

  onFilter = (filters) => {
    this.updateUrlParams(filters);
    // this.props.getActivities(this.props.match.params.slug, filters);
  }

  updateUrlParams = (params) => {
    saveCurrentQueryParams(params);
    this.props.history.push({
      pathname: this.props.history.location.pathname,
      search: queryString.stringify(params, { arrayFormat: 'comma' }),
    });
  }

  getAllActivities = () => {
    const filters = queryString.parse(this.props.location.search, { arrayFormat: 'comma' });
    this.props.getAllActivities(this.props.match.params.slug.toLowerCase(), filters);
  }


  fetchCartAmount = () => (getFromLocalStorage(`CART_${this.props.page?.data?.slug?.toUpperCase()}`) ? getFromLocalStorage(`CART_${this.props.page?.data?.slug?.toUpperCase()}`).length : 0);

  render() {
    const {
      state,
      page,
      activities,
      allActivities,
      authenticated,
      config,
    } = this.props;

    if (!page.data) { return null; }

    return (
      <React.Fragment>
        <MetaTags />
        <SportStadHeader
          title={page.data.title}
          menuItems={state.header.menuItems}
          backgroundImage={page.data.bannerImage}
        />
        <div className="main u-wrapper">
          <div className="u-container u-margin-top-lg u-margin-bottom-lg" role="main">
            <div className="row">
              <div className="col-xs-12">
                <div className="row-space-between">
                  <div className="col-xs">
                    <p className="u-margin-bottom-lg" dangerouslySetInnerHTML={{ __html: page.data.text }}></p>
                  </div>
                  {(config?.GROUPED_PURCHASES && authenticated) && <div className='u-margin-bottom' style={{ alignSelf: 'end' }}>
                    <ShoppingCartButton amount={this.fetchCartAmount()} />
                  </div>}
                </div>
                {(allActivities.loading || activities.loading) && <Spinner size="large" />}
                {activities.data.activities
                  && <ActivityResult
                    filters={page.data.filters.showOfferFilters ? [...page.data.filters.filterValues, ...ActivityFilterHardcoded] : page.data.filters.filterValues}
                    activities={activities.data}
                    onPageChange={this.changePage}
                    onSortChange={this.changeSort}
                    onFilter={this.onFilter}
                    onMapEnter={this.getAllActivities}
                    allActivities={allActivities}
                  />
                }
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Overview.propTypes = {
  activities: PropTypes.any,
  allActivities: PropTypes.any,
  getActivities: PropTypes.any,
  getAllActivities: PropTypes.any,
  getPage: PropTypes.func,
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
  page: PropTypes.any,
  state: PropTypes.object,
};

const mapStateToProps = state => ({
  page: {
    loading: state.pageReducer.loading,
    error: state.pageReducer.error,
    data: state.pageReducer.page,
  },
  activities: {
    loading: state.activitiesReducer.loading,
    error: state.activitiesReducer.error,
    data: state.activitiesReducer.activities,
  },
  allActivities: {
    loading: state.activitiesReducer.loading,
    error: state.activitiesReducer.error,
    data: state.activitiesReducer.allActivities.activities,
  },
  authenticated: state.auth.authenticated,
});


const mapDispatchToProps = dispatch => ({
  getPage: (slug) => {
    dispatch(getPage(slug));
  },
  getActivities: (slug, params) => {
    dispatch(getActivities(slug, params));
  },
  getAllActivities: (slug, params) => {
    dispatch(getAllActivities(slug, params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
