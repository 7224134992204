import React, { Component } from 'react';
import { Button } from '@acpaas-ui/react-components';
import PropTypes from 'prop-types';

import ActivitySorting from '../ActivitySorting';
import ActivityFilter from '../ActivityFilter';

class ActivityResultListHeader extends Component {
  onToggleViewMode = () => {
    const { onViewModeToggled, viewMode } = this.props;
    const newViewMode = viewMode === 'list' ? 'map' : 'list';
    if (onViewModeToggled) {
      onViewModeToggled(newViewMode);
    }
  };

  render() {
    const {
      viewMode, onSortChanged, sortOptions = [], sort, totalActivities,
    } = this.props;

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-xs">
            <ActivityFilter filters={this.props.filters} viewMode={viewMode} onFilter={this.props.onFilter} />
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-xs">
            <span className="h4">{totalActivities} activiteiten gevonden</span>
          </div>
          <div className="col-xs-12 col-sm-3 col-lg-2 last-sm">
            <Button type="primary" outline block onClick={() => this.onToggleViewMode()}>
              {viewMode === 'list' ? 'Kaartweergave' : 'Lijstweergave'}
            </Button>
          </div>
          {viewMode === 'list' ? (
            <div className="col-xs-12 col-sm-5 u-text-right">
              <ActivitySorting
                options={sortOptions}
                value={sort}
                onChange={val => onSortChanged(val)}
              />
            </div>
          ) : null}
        </div>
      </React.Fragment>
    );
  }
}

ActivityResultListHeader.propTypes = {
  onViewModeToggled: PropTypes.func.isRequired,
  viewMode: PropTypes.oneOf(['list', 'map']),
  onSortChanged: PropTypes.func.isRequired,
  sort: PropTypes.string,
  sortOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
};

export default ActivityResultListHeader;
