function parseBody(response) {
  const contentType = response.headers.get('content-type');

  if (!contentType) {
    return Promise.resolve();
  }

  if (contentType.includes('json')) {
    return response.json();
  }

  if (contentType.includes('text')) {
    return response.text();
  }

  if (contentType.includes('form-data') >= 0) {
    return response.formData();
  }

  return response;
}

export default function ResponseHandler(response) {
  if (response.status === 503) {
    // PV IS THIS THE ISSUEEEE????
    console.log('ERROR 503 - reload triggered')
    return window.location.reload();
  }
  const body = parseBody(response);

  if (response.ok) {
    return body;
  }

  return body.then((parsed) => {
    throw Object.assign({ status: response.status }, parsed);
  });
}
