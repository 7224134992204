
const tokenKey = 'SPORT_API_TOKEN';

export const getFromLocalStorage = key => JSON.parse(localStorage.getItem(key));
export const clearLocalStorage = key => localStorage.removeItem(key);
export const saveToLocalStorage = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

export const storeToken = token => window.localStorage.setItem(tokenKey, token);
export const clearToken = () => window.localStorage.removeItem(tokenKey);
export const getToken = () => window.localStorage.getItem(tokenKey);
