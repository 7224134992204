import moment from 'moment';

import isValidNationalNumber from './Validators/NationalNumber';
import { isCorrectAge, buildErrorMessageAge } from './Validators/age';
import { isNotFilledAndNotChecked, isFilledAndChecked } from './Validators/conditionalRequiredNationalNumber';
import isValidEmail from './Validators/email';
// import isValidPhone from './Validators/phone';
import checkBirthDateNationalNumber from './Validators/checkBirthDateNationalNumber';


const ERROR_MESSAGE_REQUIRED = 'Dit is een verplicht veld';
const ERROR_MESSAGE_BIRTHDATE_NATIONALNUMBER_CHECK = 'Het rijksregisternummer komt niet overeen met de geboortedatum.';


function validateField(name, value, validators, fields) {
  const errors = [];

  if (!validators) {
    return [];
  }
  validators.forEach((validator) => {
    switch (validator.name) {
      case 'isRequired':
        if (!value) { return errors.push(ERROR_MESSAGE_REQUIRED); }
        return value.length < 1
          ? errors.push(ERROR_MESSAGE_REQUIRED)
          : [];
      case 'nationalNumber':
        if (!value) {
          return [];
        } if (!isValidNationalNumber(value)) {
          return errors.push(validator.errorMessage);
        } if (!checkBirthDateNationalNumber(fields)) {
          return errors.push(ERROR_MESSAGE_BIRTHDATE_NATIONALNUMBER_CHECK);
        }
        break;
      case 'nationalNumberWithoutBirthdateCheck':
        if (!value) {
          return [];
        } if (!isValidNationalNumber(value)) {
          return errors.push(validator.errorMessage);
        }
        break;
      case 'birthdate':
        if (!moment(value, 'DD/MM/YYYY', true).isValid()) {
          return errors.push('Dit is een ongeldige geboortedatum.');
        }
        if (!isCorrectAge(value, validator.params)) {
          return errors.push(buildErrorMessageAge(validator.params));
        }
        break;
      case 'email':
        if (!isValidEmail(value, validators)) {
          return errors.push(validator.errorMessage);
        }
        break;
      /* case 'phone':
        if (!isValidPhone(value, validator.params)) {
          return errors.push(validator.errorMessage);
        }
        break; */
      case 'conditionalRequiredNationalNumber':
        if (isNotFilledAndNotChecked(value, fields)) {
          return errors.push('U moet dit veld aanvinken indien u geen rijksregisternummer heeft');
        }

        if (isFilledAndChecked(value, fields)) {
          return errors.push('U moet dit veld niet aanvinken als u een rijksregisternummer heeft ingevuld');
        }
        break;
      default:
        return null;
    }
  });

  return errors;
}

function createValidators(field) {
  const validators = field.validators ? [...field.validators] : [];
  if (field.required) {
    validators.push({ name: 'isRequired', errorMessage: field.warningMessage || 'Gelieve een waarde in te vullen' });
  }


  return validators;
}

function validateFields(fields, validators, otherValues) {
  const errors = {};

  for (const [name, value] of Object.entries(fields)) {
    const fieldValidators = validators[name];
    const fieldErrors = Validation.validateField(name, value, fieldValidators, fields);
    if (fieldErrors.length > 0) {
      errors[name] = fieldErrors;
    }
  }

  return errors;
}

export const Validation = {
  validateFields,
  validateField,
  createValidators,
};
