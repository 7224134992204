import {
  fetchRegistrations,
  removeRegistration,
  payRegistrations,
  fetchCompletedRegistrations,
  fetchRegistrationById,
  cancelRegistration,
} from '../api/Registrations';

import {
  fetchRegistrationsBegin,
  fetchRegistrationsSuccess,
  fetchRegistrationsFailure,
  deleteRegistrationBegin,
  deleteRegistrationSuccess,
  deleteRegistrationsFailure,
  payRegistrationsBegin,
  payRegistrationsSuccess,
  payRegistrationsFailure,
  fetchCompletedRegistrationsBegin,
  fetchCompletedRegistrationsSuccess,
  fetchCompletedRegistrationsFailure,
  fetchRegistrationBegin,
  fetchRegistrationFailure,
  fetchRegistrationSuccess,
} from './types';

export const getRegistrationById = id => (dispatch) => {
  dispatch(fetchRegistrationBegin());
  return fetchRegistrationById(id).then(
    data => dispatch(fetchRegistrationSuccess(data)),
    err => dispatch(fetchRegistrationFailure(err)),
  );
};

export const getRegistrations = () => (dispatch) => {
  dispatch(fetchRegistrationsBegin());
  return fetchRegistrations().then(
    data => dispatch(fetchRegistrationsSuccess(data)),
    err => dispatch(fetchRegistrationsFailure(err)),
  );
};


export const getCompletedRegistrations = () => (dispatch) => {
  dispatch(fetchCompletedRegistrationsBegin());
  return fetchCompletedRegistrations().then(
    data => dispatch(fetchCompletedRegistrationsSuccess(data)),
    err => dispatch(fetchCompletedRegistrationsFailure(err)),
  );
};
export const deleteRegistration = id => (dispatch) => {
  dispatch(deleteRegistrationBegin());
  return removeRegistration(id).then(
    data => dispatch(deleteRegistrationSuccess(data)),
    err => dispatch(deleteRegistrationsFailure(err)),
  );
};

export const payOffRegistrations = () => (dispatch) => {
  dispatch(payRegistrationsBegin());
  return payRegistrations().then(
    data => dispatch(payRegistrationsSuccess(data)),
    err => dispatch(payRegistrationsFailure(err)),
  );
};

// export const CancelRegistrationById = id => (dispatch) => {
//   dispatch(deleteRegistrationBegin());
//   return cancelRegistration(id).then(
//     data => dispatch(deleteRegistrationSuccess(data)),
//     err => dispatch(deleteRegistrationsFailure(err)),
//   );
// };
