/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router-dom';

import { navigateToLatestPage } from 'util/links';
import {
  beginRegistrationForm,
  getRegistrationForm,
  submitRegistrationForm,
  clearRegistrationForm,
  beginCancelRegistrationForm,
} from 'actions/RegistrationForm';
import { Notifications } from 'components/Layout/Notifications';
import { getFromLocalStorage, saveToLocalStorage } from 'util/localStorage';
import FormRenderer from 'components/FormRenderer';

import { authenticatedSelector } from 'selectors/auth.selectors';
import IntroStep from './IntroStep';

import './Subscribe.scss';

const BackButton = (props) => {
  const history = useHistory();
  const goToOverview = (e) => {
    props.clearRegistrationForm();
    e.preventDefault();
    navigateToLatestPage(history, true);
  };

  return (
    <div className="row u-margin-bottom">
      <div className="col-xs">
        <a onClick={goToOverview} href='#' className="back-to-activities-button">
          <span className="fa fa-chevron-left"></span>
          <span className="back-link"><b>Terug naar het aanbod</b></span>
        </a>
      </div>
    </div>
  );
};

const Register = (props) => {
  const history = useHistory();
  const [formPhase, setFormPhase] = useState('intro');
  // const location = useLocation();
  // prevents jumping to phase 2 if form is already present
  const [isNewSubmission, setIsNewSubmission] = useState(true);
  const [aCardActive] = useState(true);
  const [infant, setInfant] = useState({});
  const [data, setData] = useState({});
  // const [openWaitingListPopUp, setOpenWaitingListPopUp] = useState(false);
  const { code, slug } = useParams();
  const authenticated = useSelector(state => authenticatedSelector(state));
  const {
    activity, form, cancelRegistrationForm, config,
  } = props;
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const isWaitingList = query.get('type') === 'wachtlijst';
  const needsApproval = !!isWaitingList || !!infant.hasFosterCare || !!infant.hasSpecialNeeds;
  const allowGroupedPurchase = config?.GROUPED_PURCHASES;
  const allowAcardCoupons = config?.A_CARD_COUPONS;

  useEffect(() => {
    if (form.error) {
      if (form.error.status !== 401) {
        Notifications.error(form.error.message);
        return;
      }
    }

    if (form.form && !isNewSubmission && !form.error) {
      setFormPhase('registration');
    }
  }, [form, isNewSubmission]);

  const submit = (appliedCoupon) => {
    const payload = Object.assign({}, infant);
    Object
      .keys(data)
      .forEach(step => Object.assign(payload, data[step]));
    return props.submitRegistrationForm(code, { ...payload, aCardChequeId: appliedCoupon });
  };

  const addToCart = (id, redirectToCart = false, isFree = false) => {
    if (!needsApproval) {
      const registrationsInCart = getFromLocalStorage(`CART_${slug.toUpperCase()}`);
      const newCartContent = registrationsInCart ? [...registrationsInCart, id] : [id];
      if (id && newCartContent && !isFree) {
        saveToLocalStorage(`CART_${slug.toUpperCase()}`, newCartContent);
      }
      return redirectToCart ? history.push(`/${slug}/winkelmand`) : history.push(`/${slug}`);
    }
  };

  const onChange = (index, values) => {
    setData({ ...data, [`step-${index}`]: values });
  };

  const updateInfant = (field, value) => {
    const child = { ...infant };
    child[field] = value;
    setInfant(child);
  };

  const startFormRegistration = async (acard) => {
    props.clearRegistrationForm();
    setIsNewSubmission(false);
    const child = acard ? infant : {};
    props.getRegistrationForm(code, child);
  };

  const onCancel = () => cancelRegistrationForm(code)
    .then(() => navigateToLatestPage(history, true));


  return (
    <React.Fragment>
      <BackButton clearRegistrationForm={props.clearRegistrationForm} />
      {formPhase === 'intro'
        && <IntroStep
          activity={activity}
          aCardActive={aCardActive}
          infant={infant}
          form={form}
          updateInfant={(field, value) => updateInfant(field, value)}
          isLoggedIn={authenticated || !config?.GROUPED_PURCHASES}
          startFormRegistration={aCard => startFormRegistration(aCard)}
        />
      }
      {formPhase === 'registration'
        && <FormRenderer
          slug={slug}
          infant={infant}
          data={data}
          form={form}
          isWaitingList={isWaitingList}
          onChange={onChange}
          onSubmit={submit}
          onAddToCart={allowGroupedPurchase ? addToCart : null}
          onCancel={onCancel}
          allowGroupPurchase={allowGroupedPurchase}
          allowAcardCoupons={allowAcardCoupons}
        />
      }
    </React.Fragment>
  );
};

Register.propTypes = {
  form: PropTypes.object,
  getRegistrationForm: PropTypes.func,
  history: PropTypes.object,
  match: PropTypes.object,
};

const mapStateToProps = state => ({
  form: {
    loading: state.registrationForm.loading,
    error: state.registrationForm.error,
    type: state.registrationForm.type,
    form: state.registrationForm.form,
    registration: state.registrationForm.registration,
    result: state.registrationForm.result,
  },
});

const mapDispatchToProps = dispatch => ({
  beginRegistrationForm: (code, infant) => dispatch(beginRegistrationForm(code, infant)),
  submitRegistrationForm: (code, data) => dispatch(submitRegistrationForm(code, data)),
  getRegistrationForm: (code, infant) => dispatch(getRegistrationForm(code, infant)),
  clearRegistrationForm: () => dispatch(clearRegistrationForm()),
  cancelRegistrationForm: code => dispatch(beginCancelRegistrationForm(code)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Register);
