import Moment from 'moment';

function getBirthDateFromNationalNumber(rrnr = '') {
  let birthYear = rrnr.slice(0, 2);
  const birthMonth = rrnr.slice(2, 4);
  const birthDay = rrnr.slice(4, 6);
  const toCheck = rrnr.slice(0, 9);
  const control = rrnr.slice(9, 12);
  if (97 - (parseInt(toCheck, 10) % 97) === parseInt(control, 10)) {
    birthYear = 19 + birthYear;
  } else {
    birthYear = 20 + birthYear;
  }
  const finalDate = `${birthDay}/${birthMonth}/${birthYear}`;
  if (!Moment(finalDate, 'DD/MM/YYYY', true).isValid()) {
    return false;
  }
  return finalDate;
}

export default function checkBirthDateNationalNumber(fields = {}) {
  const { participantBirthday = '', participantNationalRegisterNumber = '' } = fields;
  const birthDateFromNationalNumber = getBirthDateFromNationalNumber(participantNationalRegisterNumber.replace(/\D/g, ''));

  return birthDateFromNationalNumber === participantBirthday;
}
