import React from 'react';
import PropTypes from 'prop-types';
import { Select } from '@acpaas-ui/react-components';

const SelectWrapper = (props) => {
  const onChange = (name, value) => {
    props.onChange(name, value);
  };

  const { field, value } = props;

  if (!field.options) {
    field.options = [];
  }

  return (<Select
    value={value}
    id={field.id}
    name={`name-${field.name}`}
    className={field.className}
    required={field.required}
    options={field.options}
    label={field.label}
    placeholder={field.placeholder || 'Gelieve een keuze te maken'}
    onChange={e => onChange(field.name, e.target.value)}
  />);
};

SelectWrapper.propTypes = {
  field: PropTypes.object,
  onChange: PropTypes.func,
  value: PropTypes.any,
};

SelectWrapper.defaultProps = {
  field: {},
};

export default SelectWrapper;
