import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete } from '@acpaas-ui/react-components';

const Street = (props) => {
  const { field, value } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [streets, setStreets] = useState([]);

  const mapStreets = responseData => responseData.map((street) => {
    const formatted = {};
    formatted.label = street.name;
    formatted.value = street.street;

    return formatted;
  });

  const fetchStreets = input => fetch(`/api/locations?types=street&search=${input}`)
    .then(res => res.json())
    .then((response) => {
      setStreets(mapStreets(response));
      setIsLoading(false);
    })
    .catch(error => console.log(error));

  const onSelect = (name, input) => {
    props.onChange(
      name,
      input,
    );
  };

  const onChange = (name, input) => {
    fetchStreets(input);
  };

  return (<Autocomplete
    key={value}
    defaultValue={value}
    items={streets}
    loading={isLoading}
    id={field.id}
    name={`name-${field.name}`}
    className={field.className}
    required={field.required}
    label={field.label}
    placeholder={field.placeholder}
    onChange={input => onChange(field.name, input)}
    onSelection={input => onSelect(field.name, input)}
  />);
};

Street.propTypes = {
  field: PropTypes.object,
  onChange: PropTypes.func,
  value: PropTypes.any,
};

Street.defaultProps = {
  field: {},
};

export default Street;
