import React from 'react';
import { Select } from '@acpaas-ui/react-components';

const ActivitySorting = ({
  value, onChange, options, ...rest
}) => (
    <React.Fragment>
      <Select
        label={'Sorteren op:'}
        id={'sort'}
        name={'sort'}
        inline
        options={options}
        value={value}
        onChange={val => onChange(val)}
        {...rest}
      />
    </React.Fragment>
);

export default ActivitySorting;
