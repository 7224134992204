import { Spinner } from '@acpaas-ui/react-components';
import ShoppingCartListItem from 'components/ShoppingCart/ShoppingCartListItem/ShoppingCartListItem';
import React from 'react';
import './ShoppingCartList.scss';

const ShoppingCartList = ({
  items = [],
  loading,
  onDelete,
  onApplyCoupon,
  hasCouponApplied,
  canApplyCouponOnItem,
}) => <div className=' m-cart-list u-margin-top'>
  {loading ? <div className='m-cart-list__loading  u-text-center u-bg-light'>
    <Spinner />
    <p className='u-margin-top'>Winkelmand wordt geladen...</p>
  </div> : null}
  {(items.length && !loading) ? items.map((item, index) => <ShoppingCartListItem
    index={index}
    canApplyCoupon={canApplyCouponOnItem}
    couponApplied={hasCouponApplied}
    key={`shopping-cart-item-${index}`}
    data={item}
    onDelete={onDelete}
    onApplyCoupon={onApplyCoupon}
  />) : null}
  {!loading && !items.length ? <div className='m-cart-list__loading  u-text-center u-bg-light'>
    <p className=''>Winkelmand is leeg</p>
  </div> : null}
</div>;

export default ShoppingCartList;
