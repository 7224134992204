import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ActivityDetail from 'components/ActivityDetail';
// import SimilarActivities from 'components/SimilarActivities';
import { SportStadHeader } from 'components/Layout/SportStadHeader';
import MetaTags from 'components/MetaTags';
import { getActivity } from 'actions/Activities';

class Activity extends Component {
  componentDidMount() {
    const { activity, match = {} } = this.props;
    if (!activity || activity.code !== match.params.code) {
      return this.props.getActivity(match.params.code);
    }

    return null;
  }

  render() {
    const {
      state,
      activity,
      loading,
    } = this.props;
    const { code } = this.props.match.params;

    const showActivity = code === activity.code && !loading;

    return (
      <React.Fragment>
        <MetaTags />
        <SportStadHeader title={activity.name} backgroundImage={activity.bannerImage} menuItems={state.header.menuItems} />
        <div className="main u-wrapper">
          <div className="u-container u-margin-top-lg u-margin-bottom-lg" role="main">
            {showActivity
              && <React.Fragment>
                <ActivityDetail activity={activity} />
                <hr />
                {/* <SimilarActivities /> */}
              </React.Fragment>
            }
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.activity.loading,
  error: state.activity.error,
  activity: state.activity.activity,
});

const mapDispatchToProps = dispatch => ({
  getActivity: (code) => {
    dispatch(getActivity(code));
  },
});

Activity.propTypes = {
  activity: PropTypes.object,
  getActivity: PropTypes.func,
  loading: PropTypes.bool,
  match: PropTypes.object,
  state: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(Activity);
