import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from '@acpaas-ui/react-components';
import { navigateToLatestPage } from 'util/links';
import './PopUp.scss';

const ConfirmationPopup = ({ specialNeeds }) => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get('type');

  let title = 'Bevestiging van je inschrijving';
  let text = <p>
    We hebben je inschrijving voor deze activiteit goed ontvangen.
    Je ontvangt snel een bevestigingsmail met praktische info.
  </p>;


  if (type === 'wachtlijst') {
    title = 'Bevestiging van je inschrijving op de wachtlijst';
    text = <React.Fragment>
      <p>
        We hebben je aanvraag goed ontvangen!
        Je staat nu op de wachtlijst en ontvangt van ons een e-mail
        zodra er een plaats voor jou vrijkomt.
      </p>
    </React.Fragment>;
  }

  if (specialNeeds) {
    title = 'Bevestiging van je aanvraag voor inschrijving met extra zorg';

    text = <React.Fragment>
      <p>
        We hebben je aanvraag voor inschrijving met extra zorg goed ontvangen!
        We bekijken deze zo snel mogelijk en houden je op de hoogte.
      </p>
    </React.Fragment>;
  }
  const goToOverview = () => navigateToLatestPage(history);
  return (
    <div className="astad-login-popup">
      <div className="m-overlay is-active">
        <div className="m-overlay__inner allow-anonymous">
          <div className="m-modal__header">
            <span><b>{title}</b></span>
          </div>
          <div className="m-modal__content m-modal-anonymous u-margin-bottom-xs">
            {text}
            <div className=" u-margin-top">
              <Button type="primary" icon="arrow-right" htmlType="button" onClick={() => { window.location.reload(); }} outline block>
              Andere deelnemer inschrijven voor deze activiteit
              </Button>
              <Button className='u-margin-top' type="primary" icon="arrow-right" htmlType="button" onClick={goToOverview}>Terug naar het aanbod</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationPopup;
