import React from 'react';
import { TagList, TagListItem } from '@acpaas-ui/react-components';
import Moment from 'moment';

import './ActivityTags.scss';

const TagName = (filtersList, filters, key) => {
  const singleFilter = filtersList.find(filter => filter.name === key);
  const filtervalue = filters[key];

  switch (singleFilter.type) {
    case 'weekpicker':
      if (singleFilter.view === 'fullWeek') {
        const firstDay = filtervalue[0];
        const lastDay = filtervalue[filtervalue.length - 1];
        return `Week: ${firstDay} - ${lastDay}`;
      }
      if (singleFilter.view === 'singleDay') {
        return `Vaste dag: ${Moment(filtervalue, 'DD/MM/YYYY').format('dddd')}`;
      }

      return `Enkele dag: ${filtervalue}`;
    default:
      return `${(filtersList.find(filter => filter.name === key)).label}: ${typeof filters[key] === 'boolean' ? 'ja' : filters[key]}`;
  }
};

const ActivityTags = ({ filters, filtersList, removeTag }) => (
  <TagList>
    {
      Object.keys(filters).map(key => <TagListItem
          value={TagName(filtersList, filters, key)}
          key={key}
          closable={true}
          onClick={() => removeTag(key)}
      />)
    }
  </TagList>
);

export default ActivityTags;
