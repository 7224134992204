import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ViewSection from '../../Sections/ViewSection';

import './ViewStep.scss';

class ViewStep extends Component {
  renderSections = (sections) => {
    const { data, errors } = this.props;

    return sections.map((section, index) => (
      <div key={index} className="u-margin-top">
        <ViewSection
          section={section}
          data={data}
          errors={errors}
          validate={this.validate}
          onChange={this.onChange}
          onBlur={this.onBlur}
        />
      </div>
    ));
  };

  renderStep = (step, stepIndex, navigate) => (
    <div className="view-step u-margin-top">
      <div>
        <h4 className="content u-margin-top u-margin-bottom" dangerouslySetInnerHTML={{ __html: step.name }}></h4>
        <button onClick={() => navigate(stepIndex)} style={{ verticalAlign: 'top' }}className="a-button-transparent u-margin-left a-button--small u-text-primary has-icon-left">
          <i className="fa fa-pencil"></i> Gegevens bewerken
        </button>
      </div>
      {this.renderSections(step.sections)}
    </div>
  )

  render() {
    const { step, stepIndex, navigate } = this.props;
    return this.renderStep(step, stepIndex, navigate);
  }
}

ViewStep.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  step: PropTypes.object,
  stepIndex: PropTypes.number,
  navigate: PropTypes.func,
};

export default ViewStep;
