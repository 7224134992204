import React from 'react';
import {
  Header,
  UserMenu,
} from '@acpaas-ui/react-components';
import {
  LOGO_REDIRECT_URL, LOGO_URL, LOGIN_URL, LOGOUT_URL, REGISTRATIONS_URL, COUPONS_URL,
} from 'constants/settings';

const AppHeader = ({
  user, authenticated, slug, config,
}) => {
  const allowLogin = !!config?.GROUPED_PURCHASES;
  return <Header logoUrl={LOGO_REDIRECT_URL} logoAlt="Antwerpen loo." logoSrc={LOGO_URL}>
    <div className="o-header__content-wrapper">
      <div className="o-header__menu-items">
        <div className="o-header__menu-item">
          {allowLogin && <UserMenu
            user={user}
            loggedIn={authenticated}
            loginUrl={`${LOGIN_URL}?fromUrl=${window.location.pathname}`}
            logoutUrl={`${LOGOUT_URL}?fromUrl=${window.location.pathname}`}
            hideExternalLinks>
            <li className='show'><a href={`/${slug}/${REGISTRATIONS_URL}`} title='Mijn inschrijvingen'>Mijn inschrijvingen</a></li>
            <li className='show'><a href={`/${slug}/${COUPONS_URL}`} title='Mijn tegoedbonnen'>Mijn tegoedbonnen</a></li>
          </UserMenu>}
        </div>
      </div>
    </div>
  </Header>;
};

export default AppHeader;
