/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { PaymentSelection } from 'containers/Pay/SharedPayElements';
import { Alert, Button } from '@acpaas-ui/react-components';
import { registrationsCheckout } from 'api/Registrations';
import { sum } from 'lodash';
import { useParams } from 'react-router-dom';
import { clearLocalStorage } from 'util/localStorage';

function formatOGM(ogm = '') {
  const toSplit = String(ogm);
  const first = toSplit.substring(0, 3);
  const second = toSplit.substring(3, 7);
  const third = toSplit.substring(7, toSplit.length);
  return `+++${first}/${second}/${third}+++`;
}

const PaymentError = () => (
  <Alert type="danger" className="u-margin-bottom">
    Er ging iets mis tijdens de betaling, hieronder kan je een nieuwe
    betaalwijze selecteren en het opnieuw proberen.
  </Alert>
);

const PayGrouped = ({
  banktransferPossible,
  registrations,
  paymentError,
  paymentSuccessful,
  goToOverview,
  setError,
  setShouldFetch,
  appliedCoupons,
  updatePaymentInfo,
  totalPrice,
}) => {
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [paymentInfo, setPaymentInfo] = useState(null);
  const [isCheckingOut, setIsCheckingOut] = useState(false);
  const hasExpiredError = registrations.find(el => el.status === 'expired');
  const { slug } = useParams();

  const checkout = async () => {
    setIsCheckingOut(true);
    const checkoutFormat = {
      registrations: registrations.map((el) => {
        const appliedCoupon = appliedCoupons.find(coupon => coupon.registrationId === el.registrationId);
        return appliedCoupon ? { id: el.registrationId, coupon: appliedCoupon.chequeId } : { id: el.registrationId };
      }),
      paymentMethod,
    };
    registrationsCheckout(checkoutFormat).then((resp) => {
      if (resp.paymentUrl) {
        window.location.href = resp.paymentUrl;
      }
      if (banktransferPossible && paymentMethod === 'banktransfer') {
        setPaymentInfo(resp);
        updatePaymentInfo(resp);
      }
      setIsCheckingOut(false);
      clearLocalStorage(`CART_${slug.toUpperCase()}`);
    }).catch(err => setError(err));
    setShouldFetch(true);
  };

  if (paymentInfo) {
    return (
      <div className='u-margin-top'>
        <p>
          We hebben je inschrijving op deze activiteit goed ontvangen.
          <br />
          Je ontvangt snel een e-mail met de juiste betaalinfo. Je
          inschrijving is pas definitief zodra we de betaling hebben
          ontvangen.
          <br />
        </p>
        <p className="u-margin-top">
          Gelieve het bedrag van
          <b> € {totalPrice} </b>
          over te schrijven naar <b> {paymentInfo.beneficiary.IBAN} </b>
          <br />
          met als gestructureerde mededeling
          <b> {formatOGM(paymentInfo.ogm)} </b> tegen
          {paymentInfo.paymentDate}.
        </p>
        <div className="row u-margin-top">
          <div className="col-md-3 col-sm-12 col-xs-12">
            <Button
              id="btn-previous-step"
              type="primary"
              htmlType="button"
              disabled={isCheckingOut}
              onClick={goToOverview}>
              Terug naar het aanbod
            </Button>
          </div>
        </div>
      </div>
    );
  }

  if (paymentSuccessful) {
    return <div className='u-margin-top'>
      <p>
        Je online betaling is gelukt! Van zodra het volledige bedrag op
        onze rekening staat, ontvang je een bevestigingsmail.
      </p>
      <div className="row u-margin-top">
        <div className="col-md-3 col-sm-12 col-xs-12">
          <Button
            id="btn-previous-step"
            type="primary"
            htmlType="button"
            disabled={isCheckingOut}
            onClick={goToOverview}>
            Terug naar het aanbod
          </Button>
        </div>
      </div>
    </div>;
  }

  return (
    <div className='u-margin-top'>
      {paymentError && <PaymentError error={paymentError} />}
      <p>
        {banktransferPossible && (
          <>
            Kies hieronder je betaalwijze: <br />
            <br />
            Kies je voor online betaling? Volg dan de verdere stappen en
            betaal meteen online. <br />
            Kies je voor bankoverschrijving? Dan ontvang je meteen na je
            keuze een e-mail met betaalinstructies. <br />
          </>
        )}
        {!banktransferPossible && (
          <>
            Kies hieronder je betaalwijze. Volg de verdere stappen en betaal
            meteen online. <br />
          </>
        )}
      </p>
      <div className="row u-margin-top" style={{ marginLeft: '2rem' }}>
        <PaymentSelection
          showBankTransfer={banktransferPossible}
          setPaymentMethod={setPaymentMethod}
          paymentMethod={paymentMethod}
        />
      </div>
      <div className="row u-margin-top">
        <div className="col-md-3 col-sm-12 col-xs-12">
          <Button
            type="primary"
            htmlType="button"
            disabled={!paymentMethod || isCheckingOut || hasExpiredError}
            onClick={() => checkout()}>
            Betaalwijze bevestigen
          </Button>
        </div>
      </div>

    </div>
  );
};

export default PayGrouped;
