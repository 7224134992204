import { stringify } from 'query-string';
import ResponseHandler from './Handler';
import headers from "./request.helpers";

export function startRegistrationForm(code, type = 'inschrijving') {
  let url = `/api/activities/${code}/registration/start`;
  if (type) {
    url += `?type=${type}`;
  }
  return fetch(url, {
    method: 'POST',
    headers: headers(),
  })
    .then(ResponseHandler);
}

export function fetchRegistrationForm(code, child) {
  const queryString = {};

  if (child.birthDate) {
    queryString.birthdate = child.birthDate;
  }

  if (child.aCardNumber) {
    queryString.acardCode = child.aCardNumber.replace(/\D/g, '');
  }

  return fetch(`/api/activities/${code}/registration/form?${stringify(queryString)}`, { method: 'GET' })
    .then(ResponseHandler);
}

export function cancelRegistrationForm(code) {
  return fetch(`/api/activities/${code}/registration/cancel`, { method: 'delete' })
    .then(ResponseHandler);
}

export function postRegistrationForm(code, formData) {
  return fetch(`/api/activities/${code}/registration/submit`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
    .then(ResponseHandler);
}
