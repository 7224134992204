import React, { Component } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import withSizes from 'react-sizes';
import { compose } from 'redux';
import { Button, Pagination } from '@acpaas-ui/react-components';
import {
  ActivityResultList,
  ActivityResultMap,
  ActivityResultListHeader,
} from '.';

import './ActivityResult.scss';
import { withRouter } from 'react-router-dom';

class ActivityResult extends Component {
  state = {
    viewMode: 'list',
    sort: '-price',
    sortOptions: [
      { key: '0', value: 'status', label: 'Status oplopend' },
      { key: '1', value: '-status', label: 'Status aflopend' },
      { key: '2', value: 'price', label: 'Prijs oplopend' },
      { key: '3', value: '-price', label: 'Prijs aflopend' },
      { key: '4', value: 'name', label: 'Naam oplopend' },
      { key: '5', value: '-name', label: 'Naam aflopend' },
      { key: '6', value: 'date', label: 'Datum oplopend' },
      { key: '7', value: '-date', label: 'Datum aflopend' },
    ],
    currentPage: this.props.activities.pagingInformation.page,
  };

  constructor(props) {
    super(props);
    const { location } = this.props;
    this.state.viewMode = queryString.parse(location.search).viewMode || 'list';
    this.state.sort = queryString.parse(location.search).sort || 'status';
  }

  getUpdatedQueryString(key, value) {
    const { location } = this.props;
    const parsed = queryString.parse(location.search);
    parsed[key] = value;
    return `?${queryString.stringify(parsed)}`;
  }

  switchViewMode = (newViewMode) => {
    const { history } = this.props;
    this.setState({ viewMode: newViewMode });
    const { slug } = this.props.match.params;
    history.push({
      pathname: `/${slug}`,
      search: this.getUpdatedQueryString('viewMode', newViewMode),
    });
  };

  switchSortMode = (newValue) => {
    this.setState({ sort: newValue.currentTarget.value });
    if (this.props.onSortChange) {
      return this.props.onSortChange(newValue.currentTarget.value);
    }
  };

  onPageChange = (number) => {
    this.setState({ currentPage: number });
    this.props.onPageChange(number);
  };

  render() {
    const {
      viewMode,
      sort,
      sortOptions,
    } = this.state;
    const { activities, allActivities, isMobile } = this.props;
    const { pagesize, totalActivities, page } = this.props.activities.pagingInformation;

    return (
      <React.Fragment>
        <ActivityResultListHeader
          viewMode={viewMode}
          onViewModeToggled={newViewMode => this.switchViewMode(newViewMode)}
          sortOptions={sortOptions}
          sort={sort}
          filters={this.props.filters}
          onFilter={this.props.onFilter}
          onSortChanged={val => this.switchSortMode(val)}
          totalActivities={this.props.activities.pagingInformation.totalActivities}
        />
        {viewMode === 'list' ? (
          <React.Fragment>
            <ActivityResultList results={activities} />
            <div className="row center-xs">
              <div className="col-xs">
                <div className="u-text-center pagination-box">
                  <Pagination
                    currentPage={page}
                    itemsPerPage={pagesize}
                    totalValues={totalActivities}
                    styling={isMobile ? 'm-pagination--small' : 'm-pagination--large'}
                    display="numbers"
                    buttonType={Button}
                    onUpdate={n => this.onPageChange(n)}
                  />
                </div>
              </div>
            </div>
          </React.Fragment>
        ) : (
            <ActivityResultMap onMapEnter={this.props.onMapEnter} allActivities={allActivities} />
        )}
      </React.Fragment>
    );
  }
}

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 480,
});

ActivityResult.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
};

export default compose(
  withRouter,
  withSizes(mapSizesToProps),
)(ActivityResult);
