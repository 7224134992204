import { stringify } from 'query-string';
import ResponseHandler from './Handler';

export function fetchActivities(slug, params) {
  delete params.viewMode;
  // traffic defender
  delete params.tdtoken;
  delete params.usels;
  if (params.viewMode) { delete params.viewMode; }
  return fetch(`/api/pages/${slug}/activities?${stringify(params, { arrayFormat: 'comma' })}`, { method: 'GET' })
    .then(ResponseHandler);
}

export function fetchAllActivities(slug, params) {
  // traffic defender
  delete params.fbclid;
  delete params.tdtoken;
  delete params.viewMode;
  return fetch(`/api/pages/${slug}/activities/all?${stringify(params, { arrayFormat: 'comma' })}`, { method: 'GET' })
    .then(ResponseHandler);
}

export function fetchActivity(code) {
  return fetch(`/api/activities/${code}`, { method: 'GET' })
    .then(ResponseHandler);
}
