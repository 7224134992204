/* eslint-disable jsx-a11y/anchor-is-valid */
import { Pagination, Spinner } from '@acpaas-ui/react-components';
import { fetchVouchersOverview } from 'actions/Voucher';
import getPage from 'actions/Pages';
import { SportStadHeader } from 'components';
import { LoginPopUp } from 'components/Layout/PopUp';
import MetaTags from 'components/MetaTags';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { authenticatedSelector } from 'selectors/auth.selectors';
import { isLoadingVouchersSelector, vouchersDataSelector, vouchersPaginationSelector } from 'selectors/vouchers.selectors';
import Table from 'components/Table/Table';
import _ from 'lodash';
import moment from 'moment';

const Vouchers = () => {
  const dispatch = useDispatch();
  const { slug } = useParams();
  const history = useHistory();

  const pageInfo = useSelector(state => ({
    loading: state.pageReducer.loading,
    error: state.pageReducer.error,
    data: state.pageReducer.page,
  }));

  const authenticated = useSelector(state => authenticatedSelector(state));
  const vouchersData = useSelector(state => vouchersDataSelector(state));
  const isLoading = useSelector(state => isLoadingVouchersSelector(state));
  const { pagination } = useSelector(state => vouchersPaginationSelector(state));

  const onUpdatePage = (p) => {
    dispatch(fetchVouchersOverview({ page: p, pageSize: pagination.pagesize }));
  };

  const goToOverview = (e) => {
    e.preventDefault();
    history.push(`/${slug}`);
  };

  const formatTableCols = () => [
    {
      label: 'Titel Activiteit',
      value: 'activityName',
    },
    {
      label: 'Deelnemer',
      value: 'participant',
    },
    {
      label: 'Betaald bedrag',
      value: 'originalPrice',
    },
    {
      label: 'Gebruikt voor',
      value: 'usedFor',
    },
    {
      label: 'Resterend bedrag',
      value: 'voucherPrice',
    },
  ];

  const formatUsedForInfo = (data) => {
    const datePart = data?.fromDate === data?.toDate ? `(${moment(data?.activityStartDate).format('DD/MM/YYYY')})` : `(${moment(data?.activityStartDate).format('DD/MM/YYYY')}-${moment(data?.activityEndDate).format('DD/MM/YYYY')})`;
    return <div className='u-margin-top-xxs u-margin-bottom-xxs'>
      <p><b>{data?.activityName} {datePart}</b></p>
      <p>{data?.submission?.participantFirstname || ''} {data?.submission?.participantLastname?.slice(0, 1) || ''}</p>
      <p>€ {data?.selectedPrice}</p>
    </div>;
  };

  const formatTableRows = () => vouchersData.map(el => ({
    id: el?._id,
    activityName: el?.reimbursedRegistration?.activityName,
    participant: el?.reimbursedRegistration?.participant?.name || '',
    originalPrice: `€ ${el?.reimbursedRegistration?.selectedPrice}`,
    usedFor: el?.voucherUsedInRegistrations?.length ? <div>
      {el?.voucherUsedInRegistrations?.map(usedRegistration => formatUsedForInfo(usedRegistration))}
    </div> : '-',
    voucherPrice: `€ ${el?.voucherAvailableValue}`,
  }));

  useEffect(() => {
    dispatch(getPage(slug.toLowerCase()));
  }, [dispatch, slug]);

  useEffect(() => {
    dispatch(fetchVouchersOverview({ page: pagination.page, pageSize: pagination.pagesize }));
  }, [dispatch, pagination.page, pagination.pagesize, slug]);

  if (!pageInfo || !pageInfo.data) { return null; }
  return <>
    <MetaTags />
    <SportStadHeader
      title={'Mijn tegoedbonnen'}
      backgroundImage={pageInfo.data.bannerImage}>
    </SportStadHeader>
    {!authenticated
        && <LoginPopUp slug={slug}/>
    }
    <div className="main u-wrapper">
      <div
        className="u-container u-margin-top-lg u-margin-bottom-lg"
        role="main">
        <div className="row-space-between u-margin-bottom-lg">
          <a onClick={goToOverview} href='#' className="back-to-activities-button">
            <span className="fa fa-chevron-left"></span>
            <span className="back-link"><b>Terug naar het aanbod</b></span>
          </a>
        </div>
        <Table
          id={'table-vouchers-overview'}
          columns={formatTableCols()}
          rows={formatTableRows()}
          // actions={tableActions()}
        />
        {isLoading
          ? <div className='u-text-center u-margin'>
            <Spinner/>
          </div>
          : <div className='row u-margin u-align-center '>
            <Pagination
              currentPage={pagination.page}
              itemsPerPage={pagination.pagesize}
              totalValues={pagination.totalValues}
              onUpdate={n => onUpdatePage(n)}
            />
          </div>
        }
      </div>
    </div>
  </>;
};

export default Vouchers;
