const ActivityFilterHardcoded = [
  {
    name: 'sortOffer',
    label: 'Kies een soort aanbod',
    type: 'radio',
    options: [
      {
        value: 'Enkele dag',
        label: 'Enkele dag',
      },
      {
        value: 'Meerdere opeenvolgende dagen',
        label: 'Meerdere opeenvolgende dagen',
      },
      {
        value: 'Vaste dag in de week',
        label: 'Vaste dag in de week',
      },
      {
        value: 'Geen',
        label: 'Geen',
      },
    ],
  },
  {
    name: 'startDateMultipleDays',
    label: 'Kies een of meerdere data',
    type: 'weekpicker',
    view: 'multipleDays',
    noWeekends: true,
    autoClose: true,
    prerequisites: [
      {
        type: 'display',
        condition: {
          field: 'sortOffer',
          values: ['Enkele dag'],
        },
      },
      {
        type: 'empty',
        condition: {
          field: 'sortOffer',
          values: ['Meerdere opeenvolgende dagen', 'Vaste dag in de week', 'Geen'],
        },
      },
    ],
  },
  {
    name: 'startDateWeek',
    label: 'Kies een week',
    view: 'fullWeek',
    type: 'weekpicker',
    noWeekends: true,
    autoClose: true,
    prerequisites: [
      {
        type: 'display',
        condition: {
          field: 'sortOffer',
          values: ['Meerdere opeenvolgende dagen'],
        },
      },
      {
        type: 'empty',
        condition: {
          field: 'sortOffer',
          values: ['Enkele dag', 'Vaste dag in de week', 'Geen'],
        },
      },
    ],
  },
  {
    name: 'startDateDay',
    label: 'Vanaf',
    view: 'singleDay',
    type: 'weekpicker',
    noWeekends: true,
    autoClose: true,
    prerequisites: [
      {
        type: 'display',
        condition: {
          field: 'sortOffer',
          values: ['Vaste dag in de week'],
        },
      },
      {
        type: 'empty',
        condition: {
          field: 'sortOffer',
          values: ['Enkele dag', 'Meerdere opeenvolgende dagen', 'Geen'],
        },
      },
    ],
  },
  {
    name: 'selectedDay',
    label: 'Op welke dag?',
    type: 'daypicker',
    options: [
      {
        value: 'Maandag',
        label: 'Maandag',
      },
      {
        value: 'Dinsdag',
        label: 'Dinsdag',
      },
      {
        value: 'Woensdag',
        label: 'Woensdag',
      },
      {
        value: 'Donderdag',
        label: 'Donderdag',
      },
      {
        value: 'Vrijdag',
        label: 'Vrijdag',
      },
      {
        value: 'Zaterdag',
        label: 'Zaterdag',
      },
      {
        value: 'Zondag',
        label: 'Zondag',
      },
    ],
    prerequisites: [
      {
        type: 'display',
        condition: {
          field: 'sortOffer',
          value: 'Vaste dag in de week',
        },
      },
      {
        type: 'empty',
        condition: {
          field: 'sortOffer',
          values: ['Enkele dag', 'Meerdere opeenvolgende dagen', 'Geen'],
        },
      },
    ],
  },
];

export default ActivityFilterHardcoded;
