import React, { useEffect, useState } from 'react';
import { useLocation, withRouter } from 'react-router-dom';
import { SportStadHeader } from 'components/Layout/SportStadHeader';
import CancelPopup from 'components/Layout/PopUp/CancelPopup';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  Button,
  RadioGroup,
  Alert,
  Spinner,
} from '@acpaas-ui/react-components';

import { getRegistrationById, deleteRegistration } from 'actions/Registrations';
import { getActivity } from 'actions/Activities';
import MetaTags from 'components/MetaTags';
import { defaultPaymentOptions, banktransferOption, PaymentSelection } from './SharedPayElements';

function formatOGM(ogm = '') {
  const toSplit = String(ogm);
  const first = toSplit.substring(0, 3);
  const second = toSplit.substring(3, 7);
  const third = toSplit.substring(7, toSplit.length);
  return `+++${first}/${second}/${third}+++`;
}

const PaymentError = () => (
  <Alert type="danger" className="u-margin-bottom">
    Er ging iets mis tijdens de betaling, hieronder kan je een nieuwe
    betaalwijze selecteren en het opnieuw proberen.
  </Alert>
);

const PaymentInProgressWarning = () => (
  <Alert type="warning" className="u-margin-bottom">
    Je bent mogelijk al bezig met een betaling, Indien je zeker bent dat deze
    niet doorgaat, kan je hier verder gaan.
  </Alert>
);

const CheckoutError = ({ error = {}, history, match }) => {
  const others = () => {
    const newUrl = match.url.split('/betalen/')[0];
    history.push(newUrl);
  };
  let text = 'Er ging iets onverwacht mis, Gelieve opnieuw te proberen';
  if (error.reason === 'expired') {
    text = (
      <>
        <p>
          De inschrijving is vervallen of geannuleerd, je kan niet meer betalen.
        </p>
        <Button type="danger" onClick={others} className="u-margin-top">
          Schrijf opnieuw in
        </Button>
      </>
    );
  }

  if (error.reason === 'notFound') {
    text = 'De inschrijving werd niet gevonden.';
  }

  return (
    <Alert type="danger" className="u-margin-bottom">
      {text}
    </Alert>
  );
};

// Riskant om te zeggen dat er meteen een mail ontvangen wordt
const Pay = ({
  registration,
  match,
  registrationbyId,
  deleteById,
  fetchActivity,
  history,
}) => {
  const query = new URLSearchParams(useLocation().search);
  const [activity, setActivity] = useState({});
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isCancelling, setCancelling] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [isCheckingOut, setCheckingOut] = useState(false);
  const [error, setError] = useState(null);
  const [paymentInfo, setPaymentInfo] = useState(null);


  const hasCheckoutError = registration?.status === 'expired'
    || (registration?.status.toLowerCase().includes('cancelled')
      && registration?.status !== 'orderCancelled')
    || !!error;
  const hasExpiredError = registration?.status === 'expired' || (error && error.reason === 'expired');

  // external ingenico error
  const paymentError = query.get('error');

  const others = () => {
    const newUrl = match.url.split('/betalen/')[0];
    history.push(newUrl);
  };

  const back = () => {
    const newUrl = match.url.split('/activiteit/')[0];
    history.push(newUrl);
  };

  const cancel = () => {
    setCancelLoading(true);
    deleteById(match.params.registrationId).then(() => history.push(`/${match.url.split('/')[1]}`));
  };

  const checkout = async () => {
    setCheckingOut(true);
    // console.log('CHECKOUT CALL');
    try {
      const response = await fetch(
        `/api/registrations/${match.params.registrationId}/checkout`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ paymentMethod }),
        },
      );

      const body = await response.json();

      if (!response.ok) {
        return setError(body);
      }

      if (body.paymentUrl) {
        window.location.href = body.paymentUrl;
      }

      setPaymentInfo(body);
      setCheckingOut(false);
    } catch (err) {
      setError(err);
    }
  };

  const closeCancelPopup = () => setCancelling(false);

  useEffect(() => {
    fetchActivity(match.params.code)
      .then(({ payload }) => {
        setActivity(payload.activity);
      })
      .catch(err => setError(err));
  }, [fetchActivity, match.params.code]);

  useEffect(() => {
    if (registration) {
      setLoading(false);
      return;
    }

    try {
      registrationbyId(match.params.registrationId)
        .then(({ registration: reg }) => {
          setLoading(false);

          if (reg.paymentType === 'banktransfer') {
            setPaymentInfo(reg.paymentInfo);
          }
        })
        .catch(err => setError(err));
    } catch (err) {
      setError(err);
    }
  }, [match.params.code, match.params.registrationId, registration, registrationbyId]);

  if (paymentInfo) {
    return (
      <>
        <MetaTags />
        <SportStadHeader
          title={activity.name}
          backgroundImage={''}></SportStadHeader>

        <div className="main u-wrapper">
          <div
            className="u-container u-margin-top-lg u-margin-bottom-lg"
            role="main">
            <h2 className="u-margin-bottom">Betaling</h2>
            <p>
              {' '}
              We hebben je inschrijving op deze activiteit goed ontvangen.{' '}
              <br />
              Je ontvangt snel een e-mail met de juiste betaalinfo. Je
              inschrijving is pas definitief zodra we de betaling hebben
              ontvangen.
              <br />
            </p>
            <p className="u-margin-top">
              Gelieve het bedrag van{' '}
              <b>{registration.selectedPrice || paymentInfo.selectedPrice} €</b>{' '}
              over te schrijven naar <b>{paymentInfo.beneficiary.IBAN}</b>
              <br />
              met als gestructureerde mededeling{' '}
              <b>{formatOGM(paymentInfo.ogm)}</b> tegen{' '}
              {paymentInfo.paymentDate}.
            </p>
            <div className="row u-margin-top">
              <div className="col-md-5 col-sm-12 col-xs-12">
                <Button
                  type="primary"
                  htmlType="button"
                  outline
                  onClick={() => others()}>
                  Andere deelnemer inschrijven op deze activiteit.
                </Button>
              </div>

              <div className="col-md-3 col-sm-12 col-xs-12">
                <Button
                  id="btn-previous-step"
                  type="primary"
                  htmlType="button"
                  disabled={isCheckingOut}
                  onClick={() => back(true)}>
                  Terug naar het aanbod
                </Button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  if (query.get('payment') === 'accepted') {
    return (
      <React.Fragment>
        <SportStadHeader
          title={activity.name}
          backgroundImage={''}></SportStadHeader>
        <div className="main u-wrapper">
          <div
            className="u-container u-margin-top-lg u-margin-bottom-lg"
            role="main">
            <h2 className="u-margin-bottom">Betaling</h2>
            <p>
              Je online betaling is gelukt! Van zodra het volledige bedrag op
              onze rekening staat, ontvang je een bevestigingsmail.
            </p>
            <div className="row u-margin-top">
              <div className="col-md-5 col-sm-12 col-xs-12">
                <Button
                  type="primary"
                  htmlType="button"
                  outline
                  onClick={() => others()}>
                  Andere deelnemer inschrijven op deze activiteit.
                </Button>
              </div>

              <div className="col-md-3 col-sm-12 col-xs-12">
                <Button
                  id="btn-previous-step"
                  type="primary"
                  htmlType="button"
                  disabled={isCheckingOut}
                  onClick={() => back(true)}>
                  Terug naar het aanbod
                </Button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  if (loading) {
    return (
      <>
        <SportStadHeader
          title={activity.name}
          backgroundImage={''}></SportStadHeader>
        <div
          className="u-container u-margin-top-lg u-margin-bottom-lg"
          role="main">
          <Spinner />
        </div>
      </>
    );
  }

  if (!loading && registration?.status === 'paid') {
    return (
      <React.Fragment>
        <SportStadHeader
          title={activity.name}
          backgroundImage={''}></SportStadHeader>
        <div className="main u-wrapper">
          <div
            className="u-container u-margin-top-lg u-margin-bottom-lg"
            role="main">
            <h2 className="u-margin-bottom">Betaling</h2>
            <p>Deze inschrijving werd reeds betaald.</p>
            <div className="row u-margin-top">
              <div className="col-md-3 col-sm-12 col-xs-12">
                <Button
                  id="btn-previous-step"
                  type="primary"
                  htmlType="button"
                  disabled={isCheckingOut}
                  onClick={() => back(true)}>
                  Terug naar het aanbod
                </Button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <SportStadHeader
        title={activity.name}
        backgroundImage={''}></SportStadHeader>
      {isCancelling && (
        <CancelPopup
          accept={cancel}
          decline={closeCancelPopup}
          loading={cancelLoading}
        />
      )}
      <div className="main u-wrapper">
        <div
          className="u-container u-margin-top-lg u-margin-bottom-lg"
          role="main">
          <h2 className="u-margin-bottom">Betaling</h2>
          {registration?.status === 'approved' && (
            <Alert type="warning" className="u-margin-bottom ">
              Opgelet, van zodra je start met je betaling, heb je 60 minuten
              tijd om deze te voltooien.
            </Alert>
          )}
          {hasCheckoutError && (
            <CheckoutError
              error={error || { reason: 'expired' }}
              history={history}
              match={match}
            />
          )}
          {!paymentError && registration?.status === 'paymentInProgress' && (
            <PaymentInProgressWarning />
          )}
          {paymentError && <PaymentError error={paymentError} />}
          <p>
            {registration.banktransferPossible && (
              <>
                Kies hieronder je betaalwijze: <br />
                <br />
                Kies je voor online betaling? Volg dan de verdere stappen en
                betaal meteen online. <br />
                Kies je voor bankoverschrijving? Dan ontvang je meteen na je
                keuze een e-mail met betaalinstructies. <br />
              </>
            )}
            {!registration.banktransferPossible && (
              <>
                Kies hieronder je betaalwijze. Volg de verdere stappen en betaal
                meteen online. <br />
              </>
            )}
          </p>
          <div className="row u-margin-top" style={{ marginLeft: '2rem' }}>
            <PaymentSelection
              showBankTransfer={registration.banktransferPossible}
              setPaymentMethod={setPaymentMethod}
              paymentMethod={paymentMethod}
            />
          </div>
          <div className="row u-margin-top">
            <div className="col-md-3 col-sm-12 col-xs-12">
              <Button
                type="primary"
                htmlType="button"
                disabled={!paymentMethod || isCheckingOut || hasExpiredError}
                onClick={() => checkout()}>
                Betaalwijze bevestigen
              </Button>
            </div>

            <div className="col-md-3 col-sm-12 col-xs-12">
              <Button
                id="btn-previous-step"
                type="primary"
                htmlType="button"
                disabled={isCheckingOut}
                negative
                onClick={() => setCancelling(true)}>
                Inschrijving annuleren
              </Button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state, { match }) => ({
  registration: state.registrations.registrations[match.params.registrationId],
});

const mapDispatchToProps = dispatch => ({
  registrationbyId: id => dispatch(getRegistrationById(id)),
  deleteById: id => dispatch(deleteRegistration(id)),
  fetchActivity: code => dispatch(getActivity(code)),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(Pay);
