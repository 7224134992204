import React from 'react';

const Counter = ({
  status,
  shouldRegister = false,
  counter = false,
  showAvailabilities,
  className = '',
}) => {
  if (!shouldRegister || status !== 'auto' || !counter || !showAvailabilities) {
    return null;
  }

  const { free = 0, maximumParticipants } = counter;
  const freeNormalized = free > 0 ? free : 0;
  if (freeNormalized === 0) {
    return null;
  }

  return (
    <div className={className}>
      <b>{maximumParticipants} plaatsen - {freeNormalized} vrij</b>
    </div>);
};

export default Counter;
