import { fetchRegistrationsHistoryBegin, fetchRegistrationsHistoryFailure, fetchRegistrationsHistorySuccess } from 'actions/types';
import { fetchRegistrationsHistoryData } from 'api/Registrations';


export function fetchRegistrationsHistory(pagination) {
  return (dispatch) => {
    dispatch(fetchRegistrationsHistoryBegin());
    return fetchRegistrationsHistoryData(pagination).then((resp) => {
      dispatch(fetchRegistrationsHistorySuccess(resp));
    }).catch((err) => {
      dispatch(fetchRegistrationsHistoryFailure(err));
    });
  };
}


export default { fetchRegistrationsHistory };
