
import React from 'react';
import {
  Button,
} from '@acpaas-ui/react-components';
import { COUPONS_URL } from 'constants/settings';

const CancelRegistrationPopUp = ({
  accept = () => {}, decline = () => {}, registration = {}, loading = false, infoUrl,
}) => {
  if (!registration) return null;
  const userName = registration?.submission?.participantFirstname ? <>{registration?.submission?.participantFirstname} {registration?.submission?.participantLastname?.slice(0, 1)}.</> : <>{registration?.submission?.name}</>;
  return <div
    className="astad-login-popup">
    <div className="m-overlay is-active">
      <div className="m-overlay__inner allow-anonymous">
        <div className='m-modal'>
          <div className="m-modal__header">
            <span><b>Annuleren betaalde inschrijving</b></span>
          </div>
          <div className="m-modal__content">
            <div className=''>
              <p>Ben je zeker dat je betaalde inschrijving <b>{registration.activityName}</b> voor {userName} wil annuleren?
              </p>
              <p>Je krijgt een tegoedbon ter waarde van €{registration.selectedPrice} die je kan gebruiken op een
                volgende activiteit van {registration.pageSlug}. Je kan het saldo hiervan raadplegen op deze site
                onder <a href={`/${registration.pageSlug}/${COUPONS_URL}`} title='Mijn tegoedbonnen'> mijn
                  tegoedbonnen</a>.</p>
              {infoUrl && <p className='u-margin-top'>Voor meer info over deze tegoedbonnen kan je terecht op <a
                href={infoUrl}>{infoUrl}</a>.</p>}
              <p className='u-margin-top'> Dit kan niet ongedaan gemaakt worden.</p>
            </div>
            <Button className='u-margin-top' type="primary" htmlType="button" onClick={() => accept()} outline block
                    disabled={loading}>
              Ja
            </Button>
            <Button className='u-margin-top' type="primary" htmlType="button" onClick={() => decline()}
                    disabled={loading} block>Nee</Button>
          </div>
        </div>
      </div>
    </div>
  </div>;
};

export default CancelRegistrationPopUp;
