import headers from 'api/request.helpers';
import { stringify } from 'query-string';
import ResponseHandler from './Handler';

export function fetchRegistrations() {
  return fetch('/api/registrations/me/current', { method: 'GET' })
    .then(ResponseHandler);
}

export function fetchCompletedRegistrations() {
  return fetch('/api/registrations/me/completed', { method: 'GET' })
    .then(ResponseHandler);
}

export function payRegistrations() {
  return fetch('/api/registrations/me/submitted/checkout', { method: 'POST' })
    .then(ResponseHandler);
}

export function removeRegistration(id) {
  return fetch(`/api/registrations/${id}`, { method: 'DELETE' })
    .then(ResponseHandler);
}


export function fetchRegistrationById(id) {
  return fetch(`/api/registrations/${id}`)
    .then(ResponseHandler);
}

export function fetchRegistrationsWithPaymentInfo(data) {
  return fetch('/api/registrations/payment-info', {
    method: 'POST',
    headers: headers(),
    body: JSON.stringify(data),
  })
    .then(ResponseHandler);
}

export function registrationsCheckout(data) {
  return fetch('/api/registrations/checkout', {
    method: 'POST',
    headers: headers(),
    body: JSON.stringify(data),
  })
    .then(ResponseHandler);
}

export function fetchRegistrationsHistoryData(params) {
  return fetch(`/api/registrations/my-registrations?${stringify(params, { arrayFormat: 'comma' })}`, { method: 'GET', headers: headers() })
    .then(ResponseHandler);
}

export function cancelRegistrationById(id) {
  return fetch(`/api/registrations/${id}/reimburse`, { method: 'POST', headers: headers() })
    .then(ResponseHandler);
}
